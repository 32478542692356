import React, { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { ActivityFeed, Utils, Loading } from '@kineticdata/bundle-common';
import moment from 'moment';
import { Table } from 'reactstrap';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';
import { getDataSource } from './SourceConfig';
import { PaginationButtons } from '../shared/PaginationButtons';
import { PageTitle } from '../shared/PageTitle';
import { Link } from '@reach/router';

const Row = props => (
  <tr key={props.handle}>
    <td>{props.referenceId}</td>
    <td>{props.status}</td>
    <td>{moment(props.createdAt).format('LLL')}</td>
    <td>
      <LinkTo id={props.id} slug={props.slug} />
    </td>
  </tr>
);

const CardWrapper = props => (
  <li key={props.handle}>
    <Card {...props} />
  </li>
);

const Card = connect(state => ({
  space: state.app.space,
}))(({ space, type, status, createdAt, id }) => {
  const remedyURL = Utils.getAttributeValue(space, 'Remedy Server URL', '');
  return (
    <a
      role="button"
      area-label="To external Survey"
      href={`${remedyURL}SRM:Survey/RequesterAnswer?qual='Request Id'="${id}"`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>
        <small>Type: </small>
        {type}
      </p>
      <p style={{ margin: '0' }}>
        <small>Status: </small>
        {status}
      </p>
      <p>
        <small>Submit Date: </small>
        {moment(createdAt).format('LLL')}
      </p>
    </a>
  );
});

const LinkTo = connect(state => ({
  space: state.app.space,
  appLocation: state.app.location,
}))(({ space, slug, id, appLocation }) => {
  // const remedyURL = Utils.getAttributeValue(space, 'Remedy Server URL', '');
  // return (
  //   <a
  //     role="button"
  //     area-label="To external Survey"
  //     href={`${remedyURL}SRM:Survey/RequesterAnswer?qual='Request Id'="${id}"`}
  //     target="_blank"
  //     rel="noopener noreferrer"
  //   >
  //     <i className="fa fa-arrow-right" aria-hidden="true" />
  //   </a>
  // );
  return (
    <Link
      role="button"
      area-label="To Survey"
      to={`${appLocation}/surveys/${slug}/${id}`}
    >
      <i className="fa fa-arrow-right" aria-hidden="true" />
    </Link>
  );
});

export const SurveysComponent = props => {
  const element = props.layoutSize !== 'small' ? Row : CardWrapper;
  const dataSources = {
    survey: getDataSource({
      name: 'survey',
      type: '',
      userEmail: props.userEmail,
      row: element,
    }),
  };

  return (
    <Fragment>
      <PageTitle parts={['Surveys']} />
      <div className="table-container">
        <ActivityFeed init={true} pageSize={25} dataSources={dataSources}>
          {feedProps =>
            feedProps.loading ? (
              <Loading text="Loading surveys ..." />
            ) : feedProps.errors ? (
              <div className="text-center">An error has ocurred</div>
            ) : feedProps.dataElements && feedProps.dataElements.length > 0 ? (
              <div>
                {props.layoutSize !== 'small' ? (
                  <Table striped>
                    <caption className="visible-hidden">
                      The Survey Table
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">Originating INC/CRQ#</th>
                        <th scope="col">Status</th>
                        <th scope="col">Submit Date</th>
                        <th scope="col">More Info</th>
                      </tr>
                    </thead>
                    <tbody>{feedProps.dataElements}</tbody>
                  </Table>
                ) : (
                  <ul className="mobile--list">{feedProps.dataElements}</ul>
                )}
                <PaginationButtons {...feedProps} />
              </div>
            ) : (
              <div className="text-center">
                You have no tickets pending feedback at this time. We still want
                to hear from you! Click{' '}
                <Link to="satisfaction-survey">here</Link> for our general
                feedback form.
              </div>
            )
          }
        </ActivityFeed>
      </div>
    </Fragment>
  );
};

export const Surveys = compose(
  connect(
    (state, props) => ({
      layoutSize: state.app.layoutSize,
      formSlug: props,
      userEmail: state.app.profile.email,
    }),
    {
      setBreadcrumb: utilityActions.setBreadcrumb,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'Give Ticket Feedback',
        },
      ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(SurveysComponent);
