import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  searchSubmissions,
  SubmissionSearch,
  bundle,
} from '@kineticdata/react';
// import * as constants from '../../constants';
import { actions, types } from '../modules/submissions';

// This saga is used to build a count for the My Approvals button
export function* fetchMyApprovalsRequestSaga({ payload }) {
  const kappSlug = yield select(state => state.app.kappSlug);
  const searchBuilder = new SubmissionSearch()
    .limit(1000)
    .includes(['details'])
    .type('Approval')
    .in('values[Status]', ['Awaiting Approval'])
    .eq(`values[Assigned Individual]`, bundle.identity())
    .coreState('Draft')
    .end();

  const search = searchBuilder.build();

  const { submissions, nextPageToken, error } = yield call(searchSubmissions, {
    search,
    kapp: kappSlug,
  });

  if (error) {
    yield put(actions.fetchMyApprovalsFailure(error));
  } else {
    yield put(actions.fetchMyApprovalsSuccess({ submissions, nextPageToken }));
  }
}

export function* watchMyApprovals() {
  yield takeEvery(
    [types.FETCH_MY_APPROVALS_REQUEST],
    fetchMyApprovalsRequestSaga,
  );
}
