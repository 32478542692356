import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import { CatalogSearchContainer } from '../shared/CatalogSearchContainer';
import { PageTitle } from '../shared/PageTitle';
import {
  CategoryCard,
  CustomerFeedbackCard,
  ProblemCategoryCard,
} from '../shared/CategoryCard';
import { Requests } from '../activity_list/Requests';
import { CollapsableAlert } from '../alerts/CollapsableAlerts';
import incident from '../../assets/images/error_outline-24px.svg';
import survey from '../../assets/images/description-24px.svg';
import pendingApproval from '../../assets/images/schedule-24px.svg';
import approval from '../../assets/images/check_circle_outline-24px.svg';
import { I18n } from '@kineticdata/react';

export const Catalog = ({
  quickLinks,
  appLocation,
  reportProblemCategory,
  categories,
  alertDismissed,
  alerts,
  layoutSize,
  customerFeedbackURL,
  myApprovals,
  over1000,
}) => (
  <Fragment>
    <PageTitle parts={[]} />
    <div className="page__category">
      {/* <div className="search-services-home">
        <div className="search-services-home__wrapper">
          <div className="search-box">
            <CatalogSearchContainer />
          </div>
        </div>
      </div> */}
      <div className="page--panel">
        <div className="page--panel--three-quarters page--panel--pad-right">
          <div className="page--panel__body">
            <div className="column-container">
              <div className="column-panel">
                <div className="cards cards--seconds">
                  {reportProblemCategory && (
                    // <ProblemCategoryCard
                    //   key={reportProblemCategory.slug}
                    //   category={reportProblemCategory}
                    //   path={`categories/${reportProblemCategory.slug}`}
                    // />
                    <a
                      href="https://support.fcps.edu"
                      className="card card--category card--category--report_issue"
                    >
                      <div className="card--category__text-wrapper">
                        <h2>
                          <I18n>{reportProblemCategory.name}</I18n>
                        </h2>
                      </div>
                    </a>
                  )}
                  {/* <CustomerFeedbackCard href={customerFeedbackURL} /> */}
                </div>
                <div className="cards__category mt-4">
                  <h2>Request a Service</h2>
                  <div className="cards cards--seconds">
                    {categories.map(item => (
                      <CategoryCard
                        key={item.slug}
                        category={item}
                        path={`categories/${item.slug}`}
                      />
                    ))}
                  </div>
                </div>
                <div className="home-table mt-4">
                  <h2>Most Recent Requests</h2>
                  <Requests pageSize={5} homePage={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {layoutSize === 'xlarge' && (
          <div className="page--panel--one-quarter">
            {alerts &&
              !alertDismissed &&
              alerts.size > 0 && <CollapsableAlert alert={alerts.get(0)} />}
            {/* <div className="navigation--request">
              <h3 className="navigation--request__title">Request Displays</h3>
              <div className="navigation--request__body">
                <Link
                  className="card card--request__small"
                  to={`${appLocation}/requests`}
                >
                  <img src={incident} alt="incident icon" width="24px" />
                  <p>My Requests</p>
                </Link>
                <Link
                  className="card card--request__small"
                  to={`${appLocation}/surveys`}
                >
                  <img src={survey} alt="survey icon" width="24px" />
                  <p>Give Ticket Feedback</p>
                </Link>
                <Link
                  className="card card--request__small"
                  to={`${appLocation}/pending-approvals`}
                >
                  <img
                    src={pendingApproval}
                    alt="pending approval icon"
                    width="24px"
                  />
                  <p>Pending Approval</p>
                </Link>
                <Link
                  className={`card card--request__small${
                    myApprovals && myApprovals.size > 0
                      ? ''
                      : ' card--request--disabled'
                  } justify-content-between`}
                  to={`${appLocation}/my-approvals`}
                >
                  <div className="d-flex">
                    <img src={approval} alt="my approval icon" width="24px" />
                    <p>My Approvals</p>
                  </div>
                  <p className="mr-3">
                    {`${
                      myApprovals && myApprovals.size > 0
                        ? myApprovals.size
                        : ''
                    }${over1000 ? '+' : ''}`}
                  </p>
                </Link>
              </div>
            </div>
            <div className="navigation__links mt-4">
              <h3 className="navigation__links__title">Quick Links</h3>
              <div className="navigation__links__body">
                <ul>
                  {quickLinks.map((quickLink, idx) => (
                    <li key={`${idx}-key`} className="quick-links">
                      <a href={quickLink.url}>{quickLink.displayText}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  </Fragment>
);
