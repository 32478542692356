import { compose, lifecycle } from 'recompose';
import { Catalog } from './Catalog';
import { Utils } from '@kineticdata/bundle-common';
import { actions } from '../../redux/modules/submissions';
import { actions as quickLinksActions } from '../../redux/modules/quickLinks';
import { connect } from '../../redux/store';

const mapStateToProps = state => {
  let reportProblemCategory =
    state.servicesApp.categories &&
    state.servicesApp.categories.find(category => category.slug === 'fix-my');
  // Override name value for this specific category
  if (reportProblemCategory) {
    reportProblemCategory.name =
      'RequestIT is no longer available. Please submit tech support tickets and requests at https://support.fcps.edu. Click here to go to the new FCPS Tech Support Portal.';
  }
  const categories =
    state.servicesApp.categories &&
    state.servicesApp.categories.filter(
      category =>
        category.slug !== 'fix-my' && !category.hidden && !category.isEmpty(),
    );

  return {
    myApprovals: state.submissions.data,
    over1000: state.submissions.nextPageToken,
    submissionsError: state.submissions.error,
    appLocation: state.app.location,
    quickLinks: state.quickLinks.data,
    alertDismissed: state.utility.get('dismissed'),
    alerts: state.alerts.get('data'),
    supportGroup: Utils.isMemberOf(state.app.profile, 'Role::Support Users'),
    customerFeedbackURL: Utils.getAttributeValue(
      state.app.space,
      'Customer Feedback URL',
      null,
    ),
    layoutSize: state.app.layoutSize,
    reportProblemCategory,
    categories,
  };
};

const mapDispatchToProps = {
  fetchMyApprovals: actions.fetchMyApprovalsRequest,
  fetchQuickLinks: quickLinksActions.fetchQuickLinks,
};

export const CatalogContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchMyApprovals();
      this.props.fetchQuickLinks(this.props.supportGroup);
    },
  }),
)(Catalog);
