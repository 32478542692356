import React from 'react';

export const Footer = () => (
  <footer className="layout--footer">
    <span className="layout--footer__item">
      <p>Need more help? Contact IT Service Desk:</p>
    </span>
    <span className="layout--footer__item">
      <p>
        <i className="fa fa-envelope" aria-hidden="true" />
        <a href="mailto: techsupport@fcps.edu">techsupport@fcps.edu</a>
      </p>
    </span>
    <span className="layout--footer__item">
      <p>
        <i className="fa fa-phone" aria-hidden="true" />
        703-503-1600
      </p>
    </span>
  </footer>
);
