import React, { Fragment } from 'react';
import { connect } from '../redux/store';
import { compose, lifecycle, withState } from 'recompose';
import {
  Card,
  CardRow,
  EmptyMessage,
  Utils,
  selectVisibleKapps,
} from '@kineticdata/bundle-common';
import { PageTitle } from './shared/PageTitle';
import { actions as utilityActions } from '../redux/modules/utility';
import { I18n } from '@kineticdata/react';

export const ProfileComponent = ({ profile, error, fieldValues, locales }) => (
  <div className="page-container">
    <PageTitle parts={['Edit Profile']} />
    {profile && (
      <Fragment>
        <div className="page-panel page-panel--white">
          <section className="pt-3">
            <h2 className="section__title  mb-2" style={{ borderTop: 'none' }}>
              General
            </h2>
            <form>
              <div className="form-group required">
                <label htmlFor="displayName">
                  <I18n>Display Name</I18n>
                </label>
                <input
                  type="text"
                  id="displayName"
                  name="displayName"
                  disabled={true}
                  value={fieldValues.displayName}
                />
              </div>
              <div className="profile-input-container row">
                <div className="form-group required col-md-6">
                  <label htmlFor="email">
                    <I18n>Email</I18n>
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    disabled={true}
                    value={fieldValues.email}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phoneNumber">
                    <I18n>Phone Number</I18n>
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    disabled={true}
                    value={fieldValues.phoneNumber}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="preferredLocale">Preferred Locale</label>
                  <select
                    type="text"
                    id="preferredLocale"
                    name="preferredLocale"
                    disabled={true}
                    className="form-control"
                    value={fieldValues.preferredLocale}
                  >
                    <option value="">None Selected</option>
                    {locales.map(locale => (
                      <option
                        value={locale.code}
                        key={`${locale.code}+${locale.name}`}
                      >
                        {locale.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </section>
          <section className="mt-4">
            <h2 className="section__title">Roles</h2>
            <div className="cards cards--fourths">
              {Utils.getRoles(profile).length > 0 ? (
                Utils.getRoles(profile).map(role => (
                  <Card key={role.slug} color="subtle">
                    <CardRow className="py-1">
                      <strong>
                        <I18n>{role.name}</I18n>
                      </strong>
                    </CardRow>
                  </Card>
                ))
              ) : (
                <EmptyMessage title="No Roles Assigned" />
              )}
            </div>
          </section>
          <section>
            <h2 className="section__title">Teams</h2>
            <div className="cards cards--fourths">
              {Utils.getTeams(profile).length > 0 ? (
                Utils.getTeams(profile).map(team => (
                  <Card key={team.slug} color="subtle">
                    <CardRow className="py-1">
                      <strong>
                        <I18n>{team.name}</I18n>
                      </strong>
                    </CardRow>
                  </Card>
                ))
              ) : (
                <EmptyMessage title="No Teams Assigned" />
              )}
            </div>
          </section>
        </div>
      </Fragment>
    )}
  </div>
);

const translateProfileToFieldValues = profile => ({
  displayName: profile.displayName || '',
  email: profile.email || '',
  preferredLocale: profile.preferredLocale || '',
  timezone: profile.timezone || '',
  newPassword: '',
  confirmPassword: '',
  phoneNumber: Utils.getProfileAttributeValue(profile, 'Phone Number', ''),
  defaultKappDisplay: Utils.getProfileAttributeValue(
    profile,
    'Default Kapp Display',
    '',
  ),
});

const mapStateToProps = state => ({
  visibleKapps: selectVisibleKapps(state),
  locales: state.app.locales,
  timezones: state.app.timezones,
  profile: state.app.profile,
  adminKappSlug: Utils.getAttributeValue(
    state.app.space,
    'Admin Kapp Slug',
    'admin',
  ),
  changeManagerFormSlug: Utils.getAttributeValue(
    state.app.space,
    'Change Manager Form Slug',
    'change-manager-request',
  ),
});

export const Profile = compose(
  connect(
    mapStateToProps,
    {
      setBreadcrumb: utilityActions.setBreadcrumb,
    },
  ),
  withState('fieldValues', 'setFieldValues', props =>
    translateProfileToFieldValues(props.profile),
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'Edit Profile',
        },
      ]);
    },

    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(ProfileComponent);
