import * as constants from '../../constants';
import {
  bundle,
  searchSubmissions,
  SubmissionSearch,
  fetchBridgedResource,
} from '@kineticdata/react';

const submissionRequest = (search, row, type) => ({
  fn: searchSubmissions,
  params: (prevParams, prevResult) =>
    prevParams && prevResult
      ? prevResult.nextPageToken
        ? { ...prevParams, pageToken: prevResult.nextPageToken }
        : null
      : {
          limit: 26,
          kapp: type === 'survey' ? 'survey' : 'services',
          search,
        },
  transform: result => ({
    data: result.submissions.map(s => ({
      createdAt: s.createdAt,
      summary: s.form.name,
      slug: s.form.slug,
      status: s.values['Status'],
      id: s.id,
      handle: s.handle,
      type: s.type,
      referenceId: s.values['Reference Id'],
    })),
    nextPageToken: result.nextPageToken,
  }),
  component: row,
});

export const getDataSource = ({ name, row, userEmail }) => {
  switch (name) {
    case 'incident': {
      let bridgedResourceName = 'Incident - By Submitter';

      return {
        fn: fetchBridgedResource,
        params: (prevParams, prevResult, options) =>
          prevParams && prevResult
            ? {
                ...prevParams,
                metadata: {
                  pageSize: prevParams.metadata.pageSize,
                  offset:
                    prevParams.metadata.offset + prevParams.metadata.pageSize,
                },
              }
            : {
                kappSlug: 'services',
                metadata: {
                  pageSize: 26,
                  offset: 0,
                },
                formSlug: 'shared-resources',
                bridgedResourceName,
              },
        transform: result => ({
          data: result.records.map(s => {
            return {
              createdAt: s['Created At'],
              summary: s['Summary'],
              status: s['Status'],
              id: s['Id'],
              handle: s['Id'],
              type: 'incident',
            };
          }),
        }),
        component: row,
      };
    }
    case 'change': {
      let bridgedResourceName = 'Change - By Submitter';

      return {
        fn: fetchBridgedResource,
        params: (prevParams, prevResult, options) =>
          prevParams && prevResult
            ? {
                ...prevParams,
                metadata: {
                  pageSize: prevParams.metadata.pageSize,
                  offset:
                    prevParams.metadata.offset + prevParams.metadata.pageSize,
                },
              }
            : {
                kappSlug: 'services',
                metadata: {
                  pageSize: 26,
                  offset: 0,
                },
                formSlug: 'shared-resources',
                bridgedResourceName,
              },
        transform: result => ({
          data: result.records.map(s => {
            return {
              createdAt: s['Created At'],
              summary: s['Summary'],
              status: s['Status'],
              id: s['Id'],
              handle: s['Id'],
              type: 'change',
            };
          }),
        }),
        component: row,
      };
    }
    // case 'survey': {
    //   let bridgedResourceName = 'Survey - By Submitter Email';

    //   return {
    //     fn: fetchBridgedResource,
    //     params: (prevParams, prevResult, options) =>
    //       prevParams && prevResult
    //         ? prevResult.nextPageToken
    //           ? {
    //               ...prevParams,
    //               metadata: { pageToken: prevResult.nextPageToken },
    //             }
    //           : null
    //         : {
    //             kappSlug: 'services',
    //             formSlug: 'shared-resources',
    //             bridgedResourceName,
    //           },
    //     transform: result => ({
    //       data: result.records.map(s => {
    //         return {
    //           createdAt: s['Create Date'],
    //           type: s['Request Type'],
    //           status: s['Status'],
    //           id: s['Originating ID'],
    //         };
    //       }),
    //       nextPageToken: result.metadata.nextPageToken,
    //     }),
    //     component: row,
    //   };
    // }
    case 'survey': {
      let search = new SubmissionSearch()
        .include('details,values,form')
        .type('Survey')
        .coreState('Draft')
        .in('values[Status]', ['Draft'])
        // .or()
        .eq(`values[Assigned Individual]`, userEmail)
        .end();
      search = search.build();
      return submissionRequest(search, row, 'survey');
    }
    case 'submitted': {
      let search = new SubmissionSearch()
        .include('details,values,form')
        .type('Service')
        .coreState('Submitted')
        .in('values[Status]', ['Submitted'])
        .or()
        .eq(`values[${constants.REQUESTED_BY_FIELD}]`, bundle.identity())
        .eq(`values[${constants.REQUESTED_FOR_FIELD}]`, bundle.identity())
        .end();
      search = search.build();
      return submissionRequest(search, row, 'submitted');
    }
    case 'draft': {
      let search = new SubmissionSearch()
        .include('details,values,form')
        .type('Service')
        .coreState('Draft')
        .in('values[Status]', ['Draft'])
        .or()
        .eq(`values[${constants.REQUESTED_BY_FIELD}]`, bundle.identity())
        .eq(`values[${constants.REQUESTED_FOR_FIELD}]`, bundle.identity())
        .end();
      search = search.build();
      return submissionRequest(search, row, 'draft');
    }
    case 'pending-approvals': {
      let search = new SubmissionSearch()
        .include('details,values,form')
        .type('Service')
        .in('values[Status]', ['Pending Approval'])
        .coreState('Submitted')
        .eq(`values[${constants.REQUESTED_FOR_FIELD}]`, bundle.identity())
        .end();
      search = search.build();
      return {
        fn: searchSubmissions,
        params: (prevParams, prevResult) =>
          prevParams && prevResult
            ? prevResult.nextPageToken
              ? { ...prevParams, pageToken: prevResult.nextPageToken }
              : null
            : {
                limit: 26,
                kapp: 'services',
                search,
              },
        transform: result => ({
          data: result.submissions.map(s => ({
            createdAt: s.createdAt,
            values: s.values,
            id: s.id,
            handle: s.handle,
            formName: s.form.name,
            formSlug: s.form.slug,
          })),
          nextPageToken: result.nextPageToken,
        }),
        component: row,
      };
    }
    case 'my-approvals': {
      let search = new SubmissionSearch()
        .include('details,values,form,form.attributes,form.kapp.attributes')
        .type('Approval')
        .in('values[Status]', ['Awaiting Approval'])
        .coreState('Draft')
        .eq(`values[Assigned Individual]`, bundle.identity())
        .end();
      search = search.build();
      return {
        fn: searchSubmissions,
        params: (prevParams, prevResult) =>
          prevParams && prevResult
            ? prevResult.nextPageToken
              ? { ...prevParams, pageToken: prevResult.nextPageToken }
              : null
            : {
                limit: 26,
                kapp: 'services',
                search,
              },
        transform: result => ({
          data: result.submissions.map(s => ({
            createdAt: s.createdAt,
            values: s.values,
            id: s.id,
            handle: s.handle,
          })),
          nextPageToken: result.nextPageToken,
        }),
        component: row,
      };
    }
    default: {
      return {};
    }
  }
};
