import React, { Fragment } from 'react';
import { CoreForm } from '@kineticdata/react';
import {
  ErrorNotFound,
  ErrorUnauthorized,
  ErrorUnexpected,
} from '@kineticdata/bundle-common';
import { PageTitle } from '../shared/PageTitle';
import { I18n } from '@kineticdata/react';

export const Survey = ({
  survey,
  submissionId,
  handleCreated,
  handleCompleted,
  handleLoaded,
  values,
  kappSlug,
  formSlug,
  loading,
}) => (
  <Fragment>
    <PageTitle parts={['Survey', survey ? survey.name : '']} />
    <div className="page__category">
      <div className="page--panel page--panel--column">
        <div className="embedded-core-form--wrapper">
          {!loading ? (
            survey && survey.coreState === 'Closed' ? (
              <div class="text-center">
                This survey has already been completed.
              </div>
            ) : submissionId ? (
              <I18n submissionId={submissionId}>
                <CoreForm
                  submission={submissionId}
                  loaded={handleLoaded}
                  completed={handleCompleted}
                />
              </I18n>
            ) : (
              <I18n context={`kapps.${kappSlug}.forms.${formSlug}`}>
                <CoreForm
                  kapp={'survey'}
                  form={formSlug}
                  loaded={handleLoaded}
                  created={handleCreated}
                  completed={handleCompleted}
                  values={values}
                  notFoundComponent={ErrorNotFound}
                  unauthorizedComponent={ErrorUnauthorized}
                  unexpectedErrorComponent={ErrorUnexpected}
                />
              </I18n>
            )
          ) : (
            <I18n>Loading...</I18n>
          )}
        </div>
      </div>
    </div>
  </Fragment>
);
