import React, { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { ActivityFeed, Loading } from '@kineticdata/bundle-common';
import moment from 'moment';
import { Table } from 'reactstrap';
import { Link } from '@reach/router';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';
import { getDataSource } from './SourceConfig';
import { PaginationButtons } from '../shared/PaginationButtons';
import { PageTitle } from '../shared/PageTitle';

const Row = props => (
  <tr key={props.handle}>
    <td>{props.formName}</td>
    <td>{moment(props.createdAt).format('LLL')}</td>
    <td>{props.values['Approver Display Name']}</td>
    <td>
      <LinkTo {...props} />
    </td>
  </tr>
);

const CardWrapper = props => (
  <li key={props.handle}>
    <Card {...props} />
  </li>
);

const Card = connect(state => ({
  appLocation: state.app.location,
}))(({ appLocation, status, values, id, formName }) => {
  const component =
    status !== 'Draft' ? (
      <Link
        role="listitem"
        aria-label={`To Submission ${id}`}
        to={`${appLocation}/requests/request/${id}/review`}
      >
        <p>
          <small>Request Name: </small>
          {formName}
        </p>
        <p>
          <small>Approver: </small>
          {values['Assigned Individual Display Name']}
        </p>
      </Link>
    ) : (
      <Link
        role="listitem"
        aria-label={`To Submission ${id}`}
        to={`${appLocation}/requests/request/${id}`}
      >
        <p>
          <small>Request Name: </small>
          {formName}
        </p>
        <p>
          <small>Approver: </small>
          {values['Assigned Individual Display Name']}
        </p>
      </Link>
    );

  return component;
});

const LinkTo = connect(state => ({
  appLocation: state.app.location,
}))(({ id, appLocation, status }) => {
  const component =
    status !== 'Draft' ? (
      <Link
        role="button"
        area-label="To Submission"
        to={`${appLocation}/requests/request/${id}/review`}
      >
        <i className="fa fa-arrow-right" aria-hidden="true" />
      </Link>
    ) : (
      <Link
        role="button"
        area-label="To Submission"
        to={`${appLocation}/requests/request/${id}`}
      >
        <i className="fa fa-arrow-right" aria-hidden="true" />
      </Link>
    );

  return component;
});

export const PendingApprovalsComponent = props => {
  const element = props.layoutSize !== 'small' ? Row : CardWrapper;
  const dataSources = {
    ce: getDataSource({ name: 'pending-approvals', type: '', row: element }),
  };

  return (
    <Fragment>
      <PageTitle parts={['Pending Approvals']} />
      <div className="table-container">
        <ActivityFeed init={true} pageSize={25} dataSources={dataSources}>
          {feedProps =>
            feedProps.loading ? (
              <Loading text="Loading approvals ..." />
            ) : feedProps.errors ? (
              <div className="text-center">An error has ocurred</div>
            ) : feedProps.dataElements && feedProps.dataElements.length > 0 ? (
              <div>
                {props.layoutSize !== 'small' ? (
                  <Table striped>
                    <caption className="visible-hidden">
                      The Pending Approvals Table
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">Request Name</th>
                        <th scope="col">Submit Date</th>
                        <th scope="col">Approver</th>
                        <th scope="col">More Info</th>
                      </tr>
                    </thead>
                    <tbody>{feedProps.dataElements}</tbody>
                  </Table>
                ) : (
                  <ul className="mobile--list">{feedProps.dataElements}</ul>
                )}
                <PaginationButtons {...feedProps} />
              </div>
            ) : (
              <div className="text-center">
                There are no requests waiting to be approved
              </div>
            )
          }
        </ActivityFeed>
      </div>
    </Fragment>
  );
};

export const PendingApprovals = compose(
  connect(
    state => ({
      layoutSize: state.app.layoutSize,
    }),
    {
      setBreadcrumb: utilityActions.setBreadcrumb,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'Pending Approvals',
        },
      ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(PendingApprovalsComponent);
