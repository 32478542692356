import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { fetchCategories } from '@kineticdata/react';

import { actions, types } from '../modules/servicesApp';

export function* fetchAppDataRequestSaga() {
  const kappSlug = yield select(state => state.app.kappSlug);

  const [{ categories, error: categoriesError }] = yield all([
    call(fetchCategories, {
      kappSlug,
      include:
        'attributes,' +
        'categorizations.form,' +
        'categorizations.form.attributes[Icon],' +
        'categorizations.form.kapp',
    }),
  ]);

  if (categoriesError) {
    yield put(actions.fetchAppDataFailure(categoriesError));
  } else {
    yield put(
      actions.fetchAppDataSuccess({
        categories,
      }),
    );
  }
}

export function* watchServicesApp() {
  yield takeEvery(types.FETCH_APP_DATA_REQUEST, fetchAppDataRequestSaga);
}
