import { call, put, takeEvery, select } from 'redux-saga/effects';
import { fetchForm } from '@kineticdata/react';
import { addToastAlert } from '@kineticdata/bundle-common';
import { actions, types } from '../modules/form';

export function* fetchServiceDescriptionSaga({ payload }) {
  const kappSlug = yield select(state => state.app.kappSlug);

  const { form, error } = yield call(fetchForm, {
    kappSlug,
    formSlug: payload,
    include:
      'attributesMap[Icon],' +
      'attributesMap[Keyword],' +
      'attributesMap[How Much Does This Service Cost], ' +
      'attributesMap[How To Request This Service], ' +
      'attributesMap[When Will This Service Be Delivered], ' +
      'attributesMap[Who Can Request This Service],' +
      'attributesMap[More Information URL],' +
      'attributesMap[Redirect URL],' +
      'details',
  });

  if (error) {
    addToastAlert('Unable to fetch service description');
  } else {
    yield put(actions.fetchServiceDescriptionSuccess(form));
  }
}

export function* watchForm() {
  yield takeEvery(types.FETCH_SERVICE_DESCRIPTION, fetchServiceDescriptionSaga);
}
