import { push } from 'connected-react-router';
import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { parse } from 'query-string';
import { Form } from './Form';
import { actions } from '../../redux/modules/submission';
import { actions as utilityActions } from '../../redux/modules/utility';
import { connect } from '../../redux/store';
import { addToast } from '@kineticdata/bundle-common';

const valuesFromQueryParams = queryParams => {
  const params = parse(queryParams);
  return Object.entries(params).reduce((values, [key, value]) => {
    if (key.startsWith('values[')) {
      const vk = key.match(/values\[(.*?)\]/)[1];
      return { ...values, [vk]: value };
    }
    return values;
  }, {});
};

export const handleCompleted = props => response => {
  if (props.authenticated) {
    // Check if either currentPage is null (pre form consolidation) or
    // displayedPage.type is not 'confirmation' (post form-consolidation)
    // to determine that there is no confirmation page and we should redirect.
    if (
      !response.submission.currentPage ||
      (response.submission.displayedPage &&
        response.submission.displayedPage.type !== 'confirmation')
    ) {
      // Some forms give power users an ability to "submit again"
      if (
        response.submission.values['Submit Another'] &&
        response.submission.values['Submit Another'] === 'true'
      ) {
        props.push(
          `/kapps/${props.kappSlug}/forms/${
            props.formSlug
          }?values[Originating%20Category]=${
            response.submission.values['Originating Category']
          }`,
        );
        props.setSubmitAgain(response.submission.id);
      } else {
        props.push(
          `/kapps/${props.kappSlug}/requests/request/${
            response.submission.id
          }/confirmation`,
        );
      }

      addToast('Thank you for your submission');
    }
  }
};

export const handleCreated = props => response => {
  // Redirect to route with submission id if submission is not submitted or
  // there is a confirmation page to render, defined as currentPage is set and
  // displayedPage is undefined (pre form consolidation) or displayedPage.type
  // is 'confirmation' (post form-consolidation).
  if (
    response.submission.coreState !== 'Submitted' ||
    (response.submission.currentPage &&
      (!response.submission.displayedPage ||
        response.submission.displayedPage.type === 'confirmation'))
  ) {
    /*
     * Only modify the route if the router location does not
     * contain the embedded & cross_domain parameters. If these
     * headers are present it is an indication that the form
     * will implement it's own submitPage() callback function.
     * This was necessary to support unauthenticated forms inside
     * of iframes when using Safari. Safari will not send cookies
     * to a server if they cookie did not originate in a main parent
     * window request (third party cookies). This includes the
     * JSESSIONID cookie which is used to validate the submitter
     * access with an unauthenticated form.
     */
    if (props.authenticated || (!props.isEmbedded && !props.isCrossDomain)) {
      props.push(`${props.location.pathname}/${response.submission.id}`);
    }
  }
};

export const handleLoaded = props => form => {
  props.setForm({
    slug: form.slug(),
    name: form.name(),
    description: form.description(),
    type: form.type(),
  });
};

export const handleDelete = props => () => {
  const deleteCallback = () => {
    props.push(props.appLocation);
  };
  props.deleteSubmission({ id: props.submissionId, callback: deleteCallback });
};

export const mapStateToProps = (
  state,
  { categorySlug, subCategorySlug, formSlug },
) => {
  const subCategory = state.servicesApp.categoryGetter(subCategorySlug);
  const category = state.servicesApp.categoryGetter(categorySlug);
  const form =
    subCategory && subCategory.forms.find(form => form.slug === formSlug);
  const search = parse(state.router.location.search);
  return {
    category,
    subCategory,
    form: form ? form : state.utility.form,
    values: valuesFromQueryParams(state.router.location.search),
    kappSlug: state.app.kappSlug,
    appLocation: state.app.location,
    submission: state.submission.data,
    authenticated: state.app.authenticated,
    isPublic: search.public !== undefined,
    isEmbedded: search.embedded !== undefined,
    isCrossDomain: search.cross_domain !== undefined,
  };
};

export const mapDispatchToProps = {
  push,
  deleteSubmission: actions.deleteSubmissionRequest,
  fetchSubmission: actions.fetchSubmissionRequest,
  setBreadcrumb: utilityActions.setBreadcrumb,
  setForm: utilityActions.setForm,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('submitAgain', 'setSubmitAgain', false),
  withHandlers({ handleCompleted, handleCreated, handleLoaded, handleDelete }),
  lifecycle({
    componentDidMount() {
      const {
        form,
        category,
        subCategory,
        submissionId,
        submission,
        fetchSubmission,
      } = this.props;
      if (submissionId && !submission) {
        console.log(submissionId);
        fetchSubmission(submissionId);
      }
      if (form && category && subCategory) {
        this.props.setBreadcrumb([
          {
            displayText: 'Home',
            path: '',
          },
          {
            displayText: 'IT Service Catalog',
            path: '/categories',
          },
          {
            displayText: category.name,
            path: `/categories/${category.slug}`,
          },
          {
            displayText: `${subCategory.name}`,
            path:
              `/categories/${category.slug}` +
              `/${this.props.subCategorySlug}/${form.slug}/description`,
          },
          {
            displayText: `${this.props.form.name}`,
          },
        ]);
      } else {
        this.props.setBreadcrumb([
          {
            displayText: 'Home',
            path: '',
          },
          {
            displayText: 'IT Service Catalog',
            path: '/categories',
          },
        ]);
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.form && prevProps.form === null) {
        this.props.setBreadcrumb([
          {
            displayText: 'Home',
            path: '',
          },
          {
            displayText: 'IT Service Catalog',
            path: '/categories',
          },
          {
            displayText: `${this.props.form.name}`,
          },
        ]);
      }
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
);

export const FormContainer = enhance(Form);
