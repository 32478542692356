import React, { Fragment } from 'react';
import { TimeAgo } from '@kineticdata/bundle-common';
import { activityData } from '../../RequestActivityList';
import { I18n } from '@kineticdata/react';

export const SubmissionSubmittedHeader = ({ activity, submission }) => {
  return (
    <div className="card--timeline__header">
      <h3>
        <I18n>{activity.label}</I18n>
      </h3>
      <TimeAgo timestamp={submission.submittedAt} />
    </div>
  );
};

export const SubmissionSubmittedBody = ({ activity, submission }) => {
  const data = activityData(activity);
  return (
    <Fragment>
      {data.Comments && (
        <div className="data-list-row">
          <div className="data-list-row__col">
            <p>
              <I18n>{data.Comments}</I18n>
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};
