import React, { Fragment } from 'react';
import { CoreForm } from '@kineticdata/react';
import {
  ErrorNotFound,
  ErrorUnauthorized,
  ErrorUnexpected,
} from '@kineticdata/bundle-common';
import { PageTitle } from '../shared/PageTitle';

import { I18n } from '@kineticdata/react';

export const Form = ({
  form,
  submissionId,
  handleCreated,
  handleCompleted,
  handleLoaded,
  values,
  kappSlug,
  formSlug,
  submission,
  submitAgain,
  authenticated,
}) => (
  <Fragment>
    <PageTitle parts={[form ? form.name : '']} />
    <div className="page__category">
      <div className="page--panel page--panel--column">
        <div className="embedded-core-form--wrapper">
          {submission && submission.coreState === 'Closed' ? (
            <div class="text-center">This has already been completed.</div>
          ) : submissionId ? (
            <I18n submissionId={submissionId} public={!authenticated}>
              <CoreForm
                submission={submissionId}
                loaded={handleLoaded}
                completed={handleCompleted}
                public={!authenticated}
              />
            </I18n>
          ) : (
            <I18n
              context={`kapps.${kappSlug}.forms.${formSlug}`}
              public={!authenticated}
            >
              <CoreForm
                kapp={kappSlug}
                form={formSlug}
                key={submitAgain}
                loaded={handleLoaded}
                created={handleCreated}
                completed={handleCompleted}
                values={values}
                notFoundComponent={ErrorNotFound}
                unauthorizedComponent={ErrorUnauthorized}
                unexpectedErrorComponent={ErrorUnexpected}
                public={!authenticated}
              />
            </I18n>
          )}
        </div>
      </div>
    </div>
  </Fragment>
);
