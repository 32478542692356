import React, { Fragment } from 'react';
import { Link, Router } from '@reach/router';
import { connect } from '../../redux/store';
import { compose, withHandlers } from 'recompose';
import { bundle, I18n } from '@kineticdata/react';
import { actions as utilityActions } from '../../redux/modules/utility';

const SidebarComponent = ({ sidebarDisplay, setSidebarDisplay }) => (
  <nav className={`layout--sidebar sidebar__${sidebarDisplay}`}>
    <div className="sidebar--button">
      <button tabIndex="-1" onClick={setSidebarDisplay}>
        X
      </button>
    </div>
    <div className="sidebar--content">
      <Router>
        <SidebarCatalog path="/" />
        <SidebarShared path="categories/:categorySlug" />
        <SidebarShared path="categories" />
        <SidebarShared path="categories/:categorySlug/:subCategorySlug/:formSlug/description" />
        <SidebarShared path="categories/:categorySlug/:subCategorySlug/:formSlug" />
        <SidebarShared path="forms/:formSlug" />
        <SidebarShared path="forms" />
        <SidebarShared path="/my-approvals" />
        <SidebarShared path="/pending-approvals" />
        <SidebarShared path="/requests" />
        <SidebarShared path="/surveys" />
        <SidebarShared path="categories/:categorySlug/:subCategorySlug/:formSlug/:submissionId" />
        <SidebarShared path="forms/:formSlug/:submissionId" />
        <SidebarShared path="requests/request/:submissionId" />
        <SidebarShared path="requests/:type/request/:submissionId" />
        <SidebarShared path="/requests/request/:submissionId/:mode" />
        <SidebarShared path="/request/:type/:id" />
        {/*  
          <SidebarSearchResults path="search" />
          <SidebarSearchResults path="search/:query" />
          
        
        <RequestListContainer path="requests" />
        <RequestListContainer path="requests/:type" />
        <RequestShowContainer path="/requests/:type/request/:submissionId/:mode" />
        */}
      </Router>
    </div>
  </nav>
);

const mapStateToProps = state => ({
  sidebarDisplay: state.utility.sidebarDisplay,
});

export const mapDispatchToProps = {
  setSidebarDisplay: utilityActions.setSidebarDisplay,
};

export const Sidebar = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    setSidebarDisplay: props => () => props.setSidebarDisplay('close'),
  }),
)(SidebarComponent);

const SidebarProfile = ({ profile }) => (
  <Fragment>
    <p>Profile Options</p>
    <ul>
      <li>
        <Link to="/profile/edit">
          <I18n>
            <p>Edit Profile</p>
          </I18n>
        </Link>
      </li>
      <li>
        <a
          tabIndex="-1"
          onClick={() => localStorage.removeItem('token')}
          href={`${bundle.spaceLocation()}/app/logout`}
        >
          <I18n>
            <p>Logout</p>
          </I18n>
        </a>
      </li>
      {profile.spaceAdmin && (
        <Fragment>
          <li>
            <Link tabIndex="-1" to="/discussions">
              <I18n>
                <p>Discussions</p>
              </I18n>
            </Link>
          </li>
          <li>
            <Link to="/teams">
              <I18n>
                <p>Teams</p>
              </I18n>
            </Link>
          </li>
          <li>
            <Link tabIndex="-1" to="/settings">
              <I18n>
                <p>Settings</p>
              </I18n>
            </Link>
          </li>
        </Fragment>
      )}
    </ul>
  </Fragment>
);

const SidebarCatalog = connect(state => ({
  quickLinks: state.quickLinks.data,
  appLocation: state.app.location,
  profile: state.app.profile,
  layoutSize: state.app.layoutSize,
}))(({ appLocation, quickLinks, profile, layoutSize }) => (
  <Fragment>
    {/* <p>Request Displays</p>
    <ul>
      <li>
        <Link tabIndex="-1" to={`${appLocation}/requests`}>
          <p>My Requests</p>
        </Link>
      </li>
      <li>
        <Link tabIndex="-1" to={`${appLocation}/surveys`}>
          <p>Give Ticket Feedback</p>
        </Link>
      </li>
      <li>
        <Link tabIndex="-1" to={`${appLocation}/pending-approvals`}>
          <p>Pending Approval</p>
        </Link>
      </li>
      <li>
        <Link tabIndex="-1" to={`${appLocation}/my-approvals`}>
          <p>My Approvals</p>
        </Link>
      </li>
    </ul>
    <p>Quick Links</p>
    <ul>
      {quickLinks.map((quickLink, idx) => (
        <li key={`${idx}-key`}>
          <a tabIndex="-1" href={quickLink.url}>
            <p>{quickLink.displayText}</p>
          </a>
        </li>
      ))}
    </ul>
    {layoutSize === 'small' && <SidebarProfile profile={profile} />} */}
  </Fragment>
));

const SidebarBreadcrumb = ({ breadcrumb, appLocation }) => (
  <Fragment>
    <p>Navigation</p>
    <nav aria-label="breadcrumb">
      <ol>
        {breadcrumb.length > 0 &&
          breadcrumb.map(
            (segment, idx) =>
              segment.path !== undefined && (
                <li key={`segment-${idx}`}>
                  <Link tabIndex="-1" to={`${appLocation}${segment.path}`}>
                    <p>{segment.displayText}</p>
                  </Link>
                </li>
              ),
          )}
      </ol>
    </nav>
  </Fragment>
);

const SidebarShared = connect(state => ({
  breadcrumb: state.utility.breadcrumb,
  appLocation: state.app.location,
  profile: state.app.profile,
  layoutSize: state.app.layoutSize,
}))(props => (
  <Fragment>
    <SidebarBreadcrumb {...props} />
    {props.layoutSize === 'small' && <SidebarProfile profile={props.profile} />}
  </Fragment>
));
