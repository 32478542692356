import React from 'react';
import { CoreForm } from '@kineticdata/react';
import { I18n } from '@kineticdata/react';
import moment from 'moment';
import { WorkLogCards } from './WorkLogCards';

export const WorkLogs = props => {
  return props.workLogs && props.workLogs.length > 0 ? (
    props.workLogs.map((record, idx) => (
      <WorkLogCards key={`${moment().valueOf()}${idx}`} record={record} />
    ))
  ) : (
    <div className="itsm-work-log">
      <I18n>No Work Logs Found</I18n>
    </div>
  );
};

export const AddWorkLog = props => (
  <CoreForm kapp={props.kappSlug} values={props.values} form={props.formSlug} />
);
