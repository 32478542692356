import React, { Fragment } from 'react';
import { connect } from '../../redux/store';
import { Link } from '@reach/router';
import { compose, lifecycle } from 'recompose';
import more_info from '../../assets/images/error_outline-24px.svg';
import { I18n } from '@kineticdata/react';

import { PageTitle } from '../shared/PageTitle';
import { actions } from '../../redux/modules/form';
import { actions as utilityActions } from '../../redux/modules/utility';

const getRedirectUrl = redirectURL =>
  redirectURL &&
  !(
    redirectURL.toLowerCase().startsWith('https://') ||
    redirectURL.toLowerCase().startsWith('http://')
  )
    ? `https://${redirectURL}`
    : redirectURL;

export const ServiceDescriptionComponent = ({
  applocation,
  subcategory,
  category,
  form,
  serviceDescription,
}) =>
  serviceDescription && (
    <Fragment>
      <PageTitle parts={[form.name, 'Description']} />
      <div className="page__category">
        <div className="page--panel page--panel--column">
          <div className="card--service-description">
            <h3>
              <I18n>About This Service</I18n>
            </h3>
            <div className="description--section">
              <p>
                <I18n>What This Service Is:</I18n>
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: serviceDescription.what }}
              />
              <p>
                <I18n>Who Can Request This Service:</I18n>
              </p>
              <p dangerouslySetInnerHTML={{ __html: serviceDescription.who }} />
              <p>
                <I18n>How To Request This Service:</I18n>
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: serviceDescription.howTo }}
              />
              <p>
                <I18n>When Will This Service Be Delivered:</I18n>
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: serviceDescription.when }}
              />
              <p>
                <I18n>How Much Does This Service Cost:</I18n>
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: serviceDescription.howMuch }}
              />
            </div>
            <div className="card--service-description__buttons">
              {/**
               *  There are 3 routes to open a form
               *    1. The service is external to Kinetic
               *    2. The service is in Kinetic and part of a subcategory
               *    3. The service is in Kinetic and not part fo a subcategory
               */}
              {serviceDescription.redirectURL &&
              serviceDescription.redirectURL.length > 0 ? (
                <a
                  target="_blank"
                  href={getRedirectUrl(serviceDescription.redirectURL[0])}
                  label="Request This Service"
                  className="card--service-description__buttons--request"
                  rel="noopener noreferrer"
                >
                  <p>
                    <I18n>Request This Service</I18n>
                  </p>
                </a>
              ) : category ? (
                <Link
                  to={`${applocation}/categories/${category.slug}/${
                    subcategory.slug
                  }/${form.slug}?values[Originating Category]=${
                    subcategory.name
                  }`}
                  label="Request This Service"
                  className="card--service-description__buttons--request"
                >
                  <p>
                    <I18n>Request This Service</I18n>
                  </p>
                </Link>
              ) : (
                <Link
                  to={`${applocation}/forms/${form.slug}`}
                  label="Request This Service"
                  className="card--service-description__buttons--request"
                >
                  <p>
                    <I18n>Request This Service</I18n>
                  </p>
                </Link>
              )}
              {serviceDescription.moreInfo &&
                serviceDescription.moreInfo.length > 0 && (
                  <a
                    href={serviceDescription.moreInfo}
                    target="_blank"
                    label="More Information"
                    className="card--service-description__buttons--more-info"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={more_info}
                      alt="More Information icon"
                      width="24px"
                    />
                    <p>
                      <I18n>More Information</I18n>
                    </p>
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

const mapStateToProps = (state, props) => {
  const subcategory = state.servicesApp.categoryGetter(props.subcategorySlug);
  const category = state.servicesApp.categoryGetter(props.categorySlug);

  // The route for the external link forms do not have subcategories.
  let form;
  if (subcategory) {
    form = subcategory.forms.find(form => form.slug === props.formSlug);
  } else {
    form = state.form;
  }

  return {
    applocation: state.app.location,
    subcategory: subcategory,
    category: category,
    form: form,
    serviceDescription: state.form.serviceDescription,
  };
};

const mapStateToDispatch = {
  fetchServiceDescription: actions.fetchServiceDescription,
  setBreadcrumb: utilityActions.setBreadcrumb,
};

export const ServiceDescription = compose(
  connect(
    mapStateToProps,
    mapStateToDispatch,
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchServiceDescription(this.props.formSlug);

      if (this.props.form) {
        const breadcrumbs = this.props.subcategorySlug
          ? [
              {
                displayText: 'Home',
                path: '',
              },
              {
                displayText: 'IT Service Catalog',
                path: '/categories',
              },
              {
                displayText: this.props.category.name,
                path: `/categories/${this.props.category.slug}`,
              },
              {
                displayText: `${this.props.subcategory.name} - ${
                  this.props.form.name
                }`,
              },
            ]
          : [
              {
                displayText: 'Home',
                path: '',
              },
              {
                displayText: this.props.form.name,
              },
            ];

        this.props.setBreadcrumb(breadcrumbs);
      }
    },
    componentDidUpdate(prevProps) {
      const { form } = this.props;

      if (form && prevProps.form && form.slug !== prevProps.form.slug) {
        const breadcrumbs = this.props.subcategorySlug
          ? [
              {
                displayText: 'Home',
                path: '',
              },
              {
                displayText: 'IT Service Catalog',
                path: '/categories',
              },
              {
                displayText: this.props.category.name,
                path: `/categories/${this.props.category.slug}`,
              },
              {
                displayText: `${this.props.subcategory.name} - ${
                  this.props.form.name
                }`,
              },
            ]
          : [
              {
                displayText: 'Home',
                path: '',
              },
              {
                displayText: this.props.form.name,
              },
            ];

        this.props.setBreadcrumb(breadcrumbs);
      }
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(ServiceDescriptionComponent);
