import React from 'react';
import { Link } from '@reach/router';
import { I18n } from '@kineticdata/react';
import { getServiceImage } from '../../assets/js/imagesMap';

export const ServiceCard = ({ path, form }) => (
  <div className="card--subcategory__service">
    <I18n context={`kapps.${form.kapp && form.kapp.slug}.forms.${form.slug}`}>
      <Link
        to={path}
        className="card card--service"
        style={{
          backgroundImage: `url(${getServiceImage(
            form.icon
              .split('.')[0]
              .split('-')
              .join(''),
          )})`,
        }}
      >
        <p>
          <I18n>{form.name}</I18n>
        </p>
      </Link>
    </I18n>
  </div>
);
