import React from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { actions } from '../../redux/modules/submission';
import { connect } from '../../redux/store';

const SendMessageModalComponent = props => {
  const title =
    props.actionType === 'comment' ? 'Start Discussion' : 'Request to Cancel';

  const placeholder = "Let the fulfillment team know why you'd like to cancel";
  return (
    <Modal size="lg" isOpen toggle={props.close}>
      <div className="modal-header">
        <h4 className="modal-title">
          <button onClick={props.close} type="button" className="btn btn-link">
            Cancel
          </button>
          <span>{title}</span>
        </h4>
      </div>
      <div className="modal-body">
        <div className="modal-form">
          <textarea
            className={`form-control ${props.hasError ? 'has-error' : null}`}
            type="textarea"
            placeholder={placeholder}
            value={props.comment}
            onChange={e => props.setComment(e.target.value)}
          />
        </div>
      </div>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary"
          onClick={props.submit}
        >
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => ({
  profile: state.app.profile,
  actionType: state.submission.sendMessageType,
  submission: state.submission.data,
  appLocation: state.app.location,
});

export const mapDispatchToProps = {
  setModalOpen: actions.setSendMessageModalOpen,
  cancelSubmission: actions.cancelSubmissionRequest,
};

const close = props => () => props.setModalOpen({ isOpen: false });

const submit = props => () => {
  if (props.comment === '') {
    props.setHasError(true);
  } else {
    props.setHasError(false);
    props.cancelSubmission(props.comment);
    props.setModalOpen(false);
    props.navigate(`${props.appLocation}/pending-approvals`);
  }
};

export const SendMessageModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('hasError', 'setHasError', false),
  withState('comment', 'setComment', ''),
  withHandlers({ close, submit }),
  lifecycle({
    componentWillUnmount() {
      this.props.close();
    },
  }),
)(SendMessageModalComponent);
