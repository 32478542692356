import React, { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { ActivityFeed, Loading } from '@kineticdata/bundle-common';
import { Table } from 'reactstrap';
import { Link } from '@reach/router';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';
import { getDataSource } from './SourceConfig';
import { PaginationButtons } from '../shared/PaginationButtons';
import { PageTitle } from '../shared/PageTitle';
import moment from 'moment';

const Row = props => (
  <tr key={props.handle}>
    <td>{props.handle}</td>
    <td>{props.summary}</td>
    <td>{props.status}</td>
    <td>{moment(props.createdAt).format('LLL')}</td>
    <td>
      <LinkTo {...props} />
    </td>
  </tr>
);

const CardWrapper = props => (
  <li key={props.handle}>
    <Card {...props} />
  </li>
);

const Card = connect(state => ({
  appLocation: state.app.location,
}))(({ appLocation, type, status, id, summary }) => {
  const component =
    type === 'submission' ? (
      status !== 'Draft' ? (
        <Link
          role="listitem"
          aria-label={`To Submission ${id}`}
          to={`${appLocation}/requests/request/${id}/review`}
        >
          <p>
            <small>Summary: </small>
            {summary}
          </p>
          <p>
            <small>Status: </small>
            {status}
          </p>
        </Link>
      ) : (
        <Link
          role="listitem"
          aria-label={`To Submission ${id}`}
          to={`${appLocation}/requests/request/${id}`}
        >
          <p>
            <small>Summary: </small>
            {summary}
          </p>
          <p>
            <small>Status: </small>
            {status}
          </p>
        </Link>
      )
    ) : (
      <Link
        role="listitem"
        aria-label={`To Request ${id}`}
        to={`${appLocation}/request/${type}/${id}`}
      >
        <p>
          <small>Summary: </small>
          {summary}
        </p>
        <p>
          <small>Status: </small>
          {status}
        </p>
      </Link>
    );

  return component;
});

const LinkTo = connect(state => ({
  appLocation: state.app.location,
}))(({ type, id, appLocation, status }) => {
  const component =
    type === 'submitted' ? (
      <Link
        role="button"
        aria-label={`To Submission ${id}`}
        to={`${appLocation}/requests/request/${id}/review`}
      >
        <i className="fa fa-arrow-right" aria-hidden="true" />
      </Link>
    ) : type === 'draft' ? (
      <Link
        role="button"
        aria-label={`To Submission ${id}`}
        to={`${appLocation}/requests/request/${id}`}
      >
        <i className="fa fa-arrow-right" aria-hidden="true" />
      </Link>
    ) : (
      <Link
        role="button"
        aria-label={`To Request ${id}`}
        to={`${appLocation}/request/${type}/${id}`}
      >
        <i className="fa fa-arrow-right" aria-hidden="true" />
      </Link>
    );

  return component;
});

export const RequestsComponent = props => {
  const element = props.layoutSize !== 'small' ? Row : CardWrapper;
  const dataSources = {
    draft: getDataSource({ name: 'draft', row: element }),
    ce: getDataSource({ name: 'submitted', row: element }),
    incident: getDataSource({ name: 'incident', row: element }),
    change: getDataSource({ name: 'change', row: element }),
  };

  return (
    <Fragment>
      <PageTitle parts={['My Requests']} />
      <div
        className={`table-container ${
          props.homePage ? 'table-container__grayhead' : ''
        }`}
      >
        <ActivityFeed
          init={true}
          pageSize={props.pageSize}
          dataSources={dataSources}
          joinBy={a => {
            return Date.parse(a.createdAt);
          }}
          joinByDirection="DESC"
        >
          {feedProps =>
            feedProps.loading ? (
              <Loading text="Loading requests ..." />
            ) : feedProps.errors ? (
              <div className="text-center">An error has ocurred</div>
            ) : feedProps.dataElements && feedProps.dataElements.length > 0 ? (
              <div>
                {props.layoutSize !== 'small' ? (
                  <Table striped>
                    <caption className="visible-hidden">
                      The My Request Table
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">INC/CRQ/Handle#</th>
                        <th scope="col">Summary</th>
                        <th scope="col">Status</th>
                        <th scope="col">Submit Date</th>
                        <th scope="col">More Info</th>
                      </tr>
                    </thead>
                    <tbody>{feedProps.dataElements}</tbody>
                  </Table>
                ) : (
                  <ul className="mobile--list">{feedProps.dataElements}</ul>
                )}
                {!props.homePage && <PaginationButtons {...feedProps} />}
              </div>
            ) : (
              <div className="text-center">No requests found</div>
            )
          }
        </ActivityFeed>
      </div>
    </Fragment>
  );
};

export const Requests = compose(
  connect(
    state => ({
      layoutSize: state.app.layoutSize,
    }),
    {
      setBreadcrumb: utilityActions.setBreadcrumb,
    },
  ),
  lifecycle({
    componentDidMount() {
      !this.props.homePage &&
        this.props.setBreadcrumb([
          {
            displayText: 'Home',
            path: '',
          },
          {
            displayText: 'My Requests',
          },
        ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(RequestsComponent);
