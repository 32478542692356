import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { fileResourceURL } from '../../utils';
import { WORKLOG_FR } from '../../constants';

export const WorkLogCards = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="log-card">
      <button className="log-card-header log-card-btn" onClick={toggle}>
        <div className="work-log-summary">
          {!isOpen &&
          (record['Attachment 1 URL'] ||
            record['Attachment 2 URL'] ||
            record['Attachment 3 URL']) ? (
            <i className="fa fa-paperclip fa-fw work-log-icon" />
          ) : !isOpen ? (
            <span className="fa fa-fw" />
          ) : null}
          {record.Notes.length > 30
            ? record.Notes.substring(0, 30) + '...'
            : record.Notes}
        </div>
        {!isOpen ? (
          <div className="log-card-time">
            {moment(record['Created At']).format('MMMM Do YYYY')}
          </div>
        ) : (
          <div className="log-card-submitter">{record['Submitter']}</div>
        )}
      </button>

      <Collapse isOpen={isOpen}>
        <div className="card-body-container">
          <div className="log-card-body">
            <div className="work-log-data">
              <div className="work-log-notes">
                {record.Notes.split('\n').map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
            </div>

            {record['Attachment 1 URL'] ||
            record['Attachment 2 URL'] ||
            record['Attachment 3 URL'] ? (
              <div className="work-log-data-attachments">
                {record['Attachment 1 URL'] && (
                  <a
                    className="work-log-attachment"
                    href={fileResourceURL(
                      WORKLOG_FR,
                      record['Attachment 1 URL'],
                    )}
                    download
                  >
                    {record['Attachment 1 Filename']}
                  </a>
                )}
                {record['Attachment 2 URL'] && (
                  <a
                    className="work-log-attachment"
                    href={fileResourceURL(
                      WORKLOG_FR,
                      record['Attachment 2 URL'],
                    )}
                    download
                  >
                    {record['Attachment 2 Filename']}
                  </a>
                )}
                {record['Attachment 3 URL'] && (
                  <a
                    className="work-log-attachment"
                    href={fileResourceURL(
                      WORKLOG_FR,
                      record['Attachment 3 URL'],
                    )}
                    download
                  >
                    {record['Attachment 3 Filename']}
                  </a>
                )}
              </div>
            ) : null}

            <div className="work-log-data-footer">
              <div>
                <b>Type:</b> {record.Type}
              </div>
              <div className="work-log-footer-time">
                {moment(record['Created At']).format('MMMM Do YYYY, h:mm:ss a')}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
