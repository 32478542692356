import React, { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { ActivityFeed, Loading } from '@kineticdata/bundle-common';
import moment from 'moment';
import { Table } from 'reactstrap';
import { Link } from '@reach/router';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';
import { getDataSource } from './SourceConfig';
import { PaginationButtons } from '../shared/PaginationButtons';
import { PageTitle } from '../shared/PageTitle';

const Row = props => (
  <tr key={props.handle}>
    <td>{props.values['Requested For Display Name']}</td>
    <td>{props.values['Originating Form Name']}</td>
    <td>{moment(props.createdAt).format('LLL')}</td>
    <td>
      <LinkTo {...props} />
    </td>
  </tr>
);

const CardWrapper = props => (
  <li key={props.handle}>
    <Card {...props} />
  </li>
);

const Card = connect(state => ({
  appLocation: state.app.location,
}))(({ appLocation, status, values, id }) => (
  <Link
    role="listitem"
    aria-label={`To Submission ${id}`}
    to={`${appLocation}/requests/request/${id}`}
  >
    <p>
      <small>Request Name: </small>
      {values['Originating Form Name']}
    </p>
    <p>
      <small>Customer Name: </small>
      {values['Requested For']}
    </p>
  </Link>
));

const LinkTo = connect(state => ({
  appLocation: state.app.location,
}))(({ id, appLocation }) => (
  <Link
    role="button"
    area-label="To Submission"
    to={`${appLocation}/requests/request/${id}`}
  >
    <i className="fa fa-arrow-right" aria-hidden="true" />
  </Link>
));

export const MyApprovalsComponent = props => {
  const element = props.layoutSize !== 'small' ? Row : CardWrapper;
  const dataSources = {
    ce: getDataSource({ name: 'my-approvals', type: '', row: element }),
  };

  return (
    <Fragment>
      <PageTitle parts={['My Approvals']} />
      <div className="table-container">
        <ActivityFeed init={true} pageSize={25} dataSources={dataSources}>
          {feedProps =>
            feedProps.loading ? (
              <Loading text="Loading requests ..." />
            ) : feedProps.errors ? (
              <div className="text-center">An error has ocurred</div>
            ) : feedProps.dataElements && feedProps.dataElements.length > 0 ? (
              <div>
                {props.layoutSize !== 'small' ? (
                  <Table striped>
                    <caption className="visible-hidden">
                      The My Approvals Table
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Request Name</th>
                        <th scope="col">Submit Date</th>
                        <th scope="col">More Info</th>
                      </tr>
                    </thead>
                    <tbody>{feedProps.dataElements}</tbody>
                  </Table>
                ) : (
                  <ul className="mobile--list">{feedProps.dataElements}</ul>
                )}
                <PaginationButtons {...feedProps} />
              </div>
            ) : (
              <div className="text-center">There are no approvals</div>
            )
          }
        </ActivityFeed>
      </div>
    </Fragment>
  );
};

export const MyApprovals = compose(
  connect(
    state => ({
      layoutSize: state.app.layoutSize,
    }),
    {
      setBreadcrumb: utilityActions.setBreadcrumb,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'My Approvals',
        },
      ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(MyApprovalsComponent);
