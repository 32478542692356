import React, { Fragment } from 'react';

import { PageTitle } from '../shared/PageTitle';
import { CategoryCard } from '../shared/CategoryCard';

export const CategoryList = ({ categories }) => (
  <Fragment>
    <PageTitle parts={['Categories']} />
    <div className="page__category">
      <div className="page--panel page--panel--column">
        <div className="cards__category">
          <div className="cards cards--seconds">
            {categories.map(item => (
              <CategoryCard
                key={item.slug}
                category={item}
                path={`${item.slug}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
