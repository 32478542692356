import React from 'react';
import { compose, withHandlers } from 'recompose';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { actions as utilityActions } from '../../redux/modules/utility';
import { connect } from '../../redux/store';

export const AlertComponent = ({ alerts, modalOpen, handleToggle }) => (
  <Modal isOpen={modalOpen}>
    <ModalHeader className="modal-header--alerts" toggle={handleToggle} />
    <ModalBody>
      {alerts &&
        alerts.map((alert, idx) => {
          const { values } = alert;
          return (
            <div key={`alert-${idx}`}>
              <h2>{values['Title']}</h2>
              <p>{values['Content']}</p>
            </div>
          );
        })}
    </ModalBody>
  </Modal>
);

const mapStateToProps = state => ({
  alerts: state.alerts.get('data'),
  modalOpen: state.utility.modalOpen,
});

const mapDispatchToProps = {
  dismissAlert: utilityActions.setDismissAlert,
  setModalOpen: utilityActions.setAlertModalOpen,
};

export const AlertModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    handleToggle: props => () => {
      props.dismissAlert();
      props.setModalOpen(!props.modalOpen);
    },
  }),
)(AlertComponent);
