import { List, Record } from 'immutable';
import { Utils } from '@kineticdata/bundle-common';
import * as constants from '../../constants';
const { withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/submissions');

export const types = {
  FETCH_MY_APPROVALS_REQUEST: ns('FETCH_MY_APPROVALS_REQUEST'),
  FETCH_MY_APPROVALS_SUCCESS: ns('FETCH_MY_APPROVALS_SUCCESS'),
  FETCH_MY_APPROVALS_FAILURE: ns('FETCH_MY_APPROVALS_FAILURE'),
};

export const actions = {
  fetchMyApprovalsRequest: withPayload(types.FETCH_MY_APPROVALS_REQUEST),
  fetchMyApprovalsSuccess: withPayload(types.FETCH_MY_APPROVALS_SUCCESS),
  fetchMyApprovalsFailure: withPayload(types.FETCH_MY_APPROVALS_FAILURE),
};

export const State = Record({
  error: null,
  data: null,
  coreState: null,
  limit: constants.PAGE_SIZE,
  paging: false,
  pageToken: null,
  nextPageToken: null,
  previousPageTokens: List(),
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.FETCH_MY_APPROVALS_REQUEST:
      return state
        .set('data', null)
        .set('error', null)
        .set('coreState', payload.coreState)
        .set('limit', payload.limit || constants.PAGE_SIZE)
        .set('pageToken', null)
        .set('nextPageToken', null)
        .set('previousPageTokens', List());
    case types.FETCH_MY_APPROVALS_SUCCESS:
      return state
        .set('data', List(payload.submissions))
        .set('nextPageToken', payload.nextPageToken)
        .set('paging', false);
    case types.FETCH_MY_APPROVALS_FAILURE:
      return state.set('error', payload).set('paging', false);
    default:
      return state;
  }
};

export default reducer;
