import React from 'react';
import { Collapse } from 'reactstrap';
import { withState, withHandlers, compose } from 'recompose';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';

export const Alert = props => {
  const alert = props.alert.values;
  return (
    <div className="card--alert__body">
      <p>{alert['Title']}</p>
      <p dangerouslySetInnerHTML={{ __html: alert['Content'] }} />
    </div>
  );
};

export const CollapsableAlertComponent = props => {
  return (
    <Collapse isOpen={props.isOpen} onExited={() => props.dismissAlert()}>
      <div className="card--alert">
        <div className="card--alert__content">
          <div className="card--alert__wrapper">
            <div className="card--alert__close">
              <button
                type="button"
                aria-label="Close Alert"
                onClick={props.handleClose}
              />
            </div>
            <Alert alert={props.alert} />
          </div>
        </div>
        <div className="card--alert__footer">
          <button
            type="button"
            aria-label="Show More"
            onClick={props.handleModalOpen}
          >
            <p>show more...</p>
          </button>
        </div>
      </div>
    </Collapse>
  );
};

const mapStateToProps = state => ({
  appLocation: state.app.location,
  modalOpen: state.utility.modalOpen,
});

const mapDispatchToProps = {
  dismissAlert: utilityActions.setDismissAlert,
  setAlertModalOpen: utilityActions.setAlertModalOpen,
};

export const CollapsableAlert = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isOpen', 'setIsOpen', true),
  withHandlers({
    handleClose: props => () => {
      props.setIsOpen(false);
    },
    handleModalOpen: props => () => {
      props.setAlertModalOpen(!props.modalOpen);
    },
  }),
)(CollapsableAlertComponent);
