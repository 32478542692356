import { all } from 'redux-saga/effects';

import { watchApp } from './sagas/app';
import { watchServicesApp } from './sagas/servicesApp';
import { watchMyApprovals } from './sagas/submissions';
import { watchSubmission, watchSubmissionPoller } from './sagas/submission';
import { watchSettingsForms } from './sagas/settingsForms';
import { watchSettingsCategories } from './sagas/settingsCategories';
import { watchQuickLinks } from './sagas/quickLinks';
import { watchSearch } from './sagas/search';
import { watchActivityList } from './sagas/activityList';
import { watchAlerts } from './sagas/alerts';
import { watchForm } from './sagas/form';
import { watchSurvey } from './sagas/survey';

export default function*() {
  yield all([
    watchApp(),
    watchServicesApp(),
    watchMyApprovals(),
    watchSubmission(),
    watchSubmissionPoller(),
    watchSettingsForms(),
    watchSettingsCategories(),
    watchQuickLinks(),
    watchSearch(),
    watchActivityList(),
    watchAlerts(),
    watchForm(),
    watchSurvey(),
  ]);
}
