import { compose, lifecycle } from 'recompose';
import { Map } from 'immutable';
import { Category } from './Category';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';

const mapStateToProps = (state, props) => {
  let subcategories = Map({});
  let subcategoriesLeft = Map({});
  let subcategoriesRight = Map({});
  const category = state.servicesApp.categoryGetter(props.categorySlug);

  subcategories = category
    .getChildren()
    // remove subcategoies that don't have forms
    .filter(subcategory => subcategory.forms.size > 0)
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  subcategoriesLeft = subcategories.filter((_noop_, idx) => idx % 2 === 0);
  subcategoriesRight = subcategories.filter((_noop_, idx) => idx % 2 !== 0);

  return {
    layoutSize: state.app.layoutSize,
    category,
    subcategories,
    subcategoriesLeft,
    subcategoriesRight,
  };
};

const mapDispatchToProps = {
  setBreadcrumb: utilityActions.setBreadcrumb,
};

export const CategoryContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'IT Service Catalog',
          path: '/categories',
        },
        {
          displayText: this.props.category.name,
        },
      ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(Category);
