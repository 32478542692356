import { compose, lifecycle, withHandlers } from 'recompose';
import { CatalogSearchResults } from './CatalogSearchResults';
import { connect } from '../../redux/store';
import { actions } from '../../redux/modules/search';
import { SEARCH_LIMIT } from '../../constants';

const loadNextHandler = props => () => {
  if (props.hasNextPage) {
    props.fetchFormsNext({ query: props.query });
  }
};

const loadPreviousHandler = props => () => {
  if (props.hasPreviousPage) {
    props.fetchFormsPrevious({ query: props.query });
  }
};

const mapStateToProps = (state, props) => {
  const query = props.query || '';

  return {
    query,
    error: state.search.error,
    appLocation: state.app.location,
    forms: state.search.data,
    paging: state.search.paging,
    hasPreviousPage: state.search.previousPageTokens.size > 0,
    hasNextPage: !!state.search.nextPageToken,
    pageIndexStart:
      state.search.previousPageTokens.size * SEARCH_LIMIT +
      (state.search.data && state.search.data.size > 0 ? 1 : 0),
    pageIndexEnd:
      state.search.previousPageTokens.size * SEARCH_LIMIT +
      ((state.search.data && state.search.data.size) || 0),
    categoryHelper: state.servicesApp.categoryHelper,
  };
};

const mapDispatchToProps = {
  fetchFormsRequest: actions.fetchSearchRecords,
  fetchFormsNext: actions.fetchSearchRecordsNext,
  fetchFormsPrevious: actions.fetchSearchRecordsPrevious,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    loadNextHandler,
    loadPreviousHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchFormsRequest({ query: this.props.query });
    },
    componentDidUpdate(prevProps) {
      if (this.props.query !== prevProps.query) {
        this.props.fetchFormsRequest({ query: this.props.query });
      }
    },
  }),
);

export const CatalogSearchResultsContainer = enhance(CatalogSearchResults);
