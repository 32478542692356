import { call, put, takeEvery } from 'redux-saga/effects';
import { searchSubmissions, SubmissionSearch } from '@kineticdata/react';
import { actions, types } from '../modules/quickLinks';
import { addToastAlert } from '@kineticdata/bundle-common';

export function* fetchQuickLinksSaga({ payload }) {
  const searcher = new SubmissionSearch(true);
  searcher.include('values');

  if (payload) {
    searcher.eq('values[Support Users]', 'true').index('values[Support Users]');
  } else {
    searcher.eq('values[Support Users]', '').index('values[Support Users]');
  }

  const { submissions, error } = yield call(searchSubmissions, {
    search: searcher.build(),
    datastore: true,
    form: 'quick-links',
  });

  if (error) {
    addToastAlert('Unable to fetch quick links');
    console.error(`There was an error when fetching quick links: ${error}`);
  } else {
    yield put(actions.fetchQuickLinksSuccess(submissions));
  }
}

export function* watchQuickLinks() {
  yield takeEvery(types.FETCH_QUICK_LINKS, fetchQuickLinksSaga);
}
