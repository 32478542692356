import React from 'react';
import { navigate, Router, Redirect } from '@reach/router';
import { I18n } from '@kineticdata/react';
import { getDurationInDays, getStatus } from '../../utils';
import { TimeAgo, Utils as CommonUtils } from '@kineticdata/bundle-common';
import * as constants from '../../constants';
import { connect } from '../../redux/store';
import { getImage } from '../../assets/js/imagesMap';
import { actions } from '../../redux/modules/submission';
import { compose, lifecycle } from 'recompose';
import moment from 'moment';

import { CancelButtonContainer } from '../request/CancelButton';
import { CloneButtonContainer } from '../request/CloneButton';

const SubmissionRedirect = props => (
  <Redirect
    to={`${props.appLocation}/requests/request/${props.id}/${
      props.location.search.includes('review') ? 'review' : 'activity'
    }`}
    noThrow
  />
);

export const BannerBar = props => (
  <div className="banner-bar">
    <div className="banner-bar__container">
      <Router>
        <SubmissionRedirect
          path="submissions/:id"
          appLocation={props.appLocation}
        />
        <SubmissionRedirect
          path="forms/:formSlug/submissions/:id"
          appLocation={props.appLocation}
        />
        <BannerBarCatalog path="/" />
        <BannerBarProfile path="profile" />
        <BannerBarCategory path="categories/:categorySlug" />
        <BannerBarCategoryList path="categories" />
        <BannerBarServiceDescription path="categories/:categorySlug/:subCategorySlug/:formSlug/description" />
        <BannerBarServiceDescriptionExternalLink path=":formSlug/description" />
        <BannerBarForm path="categories/:categorySlug/:subCategorySlug/:formSlug" />
        <BannerBarForm path="categories/:categorySlug/:subCategorySlug/:formSlug/:submissionId" />
        <BannerBarForm path="forms/:formSlug" />
        <BannerBarForm path="forms/:formSlug/:submissionId" />
        <BannerBarForm path="requests/request/:submissionId" />
        <BannerBarForm path="requests/:type/request/:submissionId" />
        <BannerBarFormList path="forms" />
        <BannerBarSearchResults path="search" />
        <BannerBarSearchResults path="search/:query" />
        <BannerBarRequestShow path="/requests/request/:submissionId/:mode" />
        <BannerBarMyApprovals path="/my-approvals" />
        <BannerBarPendingApprovals path="/pending-approvals" />
        <BannerBarMyRequests path="/requests" />
        <BannerBarSurveys path="/surveys" />
        <BannerBarSurvey path="/surveys/:formSlug" />
        <BannerBarSurvey path="/surveys/:formSlug/:submissionId" />
        <BannerBarRemedyRequest path="/request/:type/:id" />
        {/*

      <RequestListContainer path="requests" />
      <RequestListContainer path="requests/:type" />
      <RequestShowContainer path="/requests/:type/request/:submissionId/:mode" />
      */}
      </Router>
    </div>
  </div>
);

const BannerBarFormList = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>All Services</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarCatalog = connect(state => ({
  firstName: CommonUtils.getAttributeValue(state.app.profile, 'First Name', ''),
  lastName: CommonUtils.getAttributeValue(state.app.profile, 'Last Name', ''),
}))(({ firstName, lastName }) => (
  <div className="banner-bar--catalog">
    <h1>Hi {`${firstName} ${lastName}`}</h1>
    <p>Welcome to the self service portal</p>
  </div>
));

const BannerBarCategory = connect((state, props) => ({
  category: state.servicesApp.categoryGetter(props.categorySlug),
}))(({ category }) => (
  <div className="page-title">
    <img
      alt={`${category.name} Icon`}
      src={getImage(category.icon.split('.')[0])}
    />
    <div className="page-title__wrapper">
      <h1>
        <I18n>{category.name}</I18n>
      </h1>
    </div>
  </div>
));

const BannerBarProfile = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>Edit Profile</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarCategoryList = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>IT Service Catalog</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarServiceDescription = connect((state, props) => {
  const subCategory = state.servicesApp.categoryGetter(props.subCategorySlug);
  const form = subCategory.forms.find(form => form.slug === props.formSlug);
  return {
    subCategory: subCategory,
    form: form,
  };
})(({ subCategory, form }) => (
  <div className="banner-bar--service-description">
    <I18n>
      <h1>{subCategory.name}</h1>
    </I18n>
    <I18n>
      <p>{form.name}</p>
    </I18n>
  </div>
));

const BannerBarServiceDescriptionExternalLink = connect((state, props) => ({
  form: state.form,
}))(({ form }) => (
  <div className="banner-bar--service-description">
    <I18n>
      <h1>External Link</h1>
    </I18n>
    <I18n>
      <p>{form && form.name}</p>
    </I18n>
  </div>
));

const BannerBarSearchResults = connect((state, props) => {
  const query = props.query || '';
  return {
    count: state.search.count,
    query,
  };
})(({ query, count }) => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>Search Results</I18n>
      </h1>
      <p>
        {count} results for "<b>{query}</b>"
      </p>
    </div>
  </div>
));

const BannerBarMyApprovals = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>My Approvals</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarPendingApprovals = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>Pending Approvals</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarMyRequests = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>My Requests</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarSurveys = () => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>Give Ticket Feedback</I18n>
      </h1>
    </div>
  </div>
);

const BannerBarSurvey = connect(state => ({
  survey: state.survey.survey,
}))(({ survey }) => (
  <div className="page-title">
    <div className="page-title__wrapper">
      <h1>
        <I18n>{survey && survey.name}</I18n>
      </h1>
    </div>
  </div>
));

const StatusItem = ({ submission, request }) => (
  <div className="data-list-row__col">
    <dl>
      <dt>
        <I18n>Status</I18n>:
      </dt>
      <dd>
        {submission ? (
          <I18n>{getStatus(submission)}</I18n>
        ) : (
          <I18n>{request.Status}</I18n>
        )}
      </dd>
    </dl>
  </div>
);

const DisplayDateItem = ({ submission, request }) =>
  submission ? (
    !submission.submittedAt ? (
      <div className="data-list-row__col">
        <dl>
          <dt>
            <I18n>Created</I18n>:
          </dt>
          <dd>
            <TimeAgo timestamp={submission.createdAt} />
          </dd>
        </dl>
      </div>
    ) : (
      <div className="data-list-row__col">
        <dl>
          <dt>
            <I18n>Submitted</I18n>:
          </dt>
          <dd className="text-truncate">
            <TimeAgo timestamp={submission.submittedAt} />
            <br />
          </dd>
        </dl>
      </div>
    )
  ) : (
    <div className="data-list-row__col">
      <dl>
        <dt>
          <I18n>Created</I18n>:
        </dt>
        <dd>
          <TimeAgo timestamp={moment(request['Created At'])} />
        </dd>
      </dl>
    </div>
  );

const BannerBarRemedyRequest = connect(state => ({
  request: state.activityList.data,
  error: state.activityList.error,
  formName: state.utility.formName,
}))(
  ({ error, request, formName }) =>
    !error &&
    request && (
      <div className="banner-bar--request">
        <h1>
          <I18n>{formName}</I18n>
        </h1>
        <div className="data-list-row">
          <StatusItem request={request} />
          <DisplayDateItem request={request} />
        </div>
      </div>
    ),
);

const ServiceOwnerItem = ({ submission }) => {
  const serviceOwner = CommonUtils.getConfig({
    submission,
    name: constants.ATTRIBUTE_SERVICE_OWNING_TEAM,
  });
  return (
    !!serviceOwner && (
      <div className="data-list-row__col">
        <dl>
          <dt>
            <I18n>Service Owning Team</I18n>:
          </dt>
          <dd>
            {serviceOwner} <I18n>Team</I18n>
          </dd>
        </dl>
      </div>
    )
  );
};

const CompletedInItem = ({ submission }) => {
  const duration =
    submission.coreState === constants.CORE_STATE_CLOSED &&
    getDurationInDays(submission.createdAt, submission.closedAt);
  return (
    (duration || duration === 0) && (
      <div className="data-list-row__col">
        <dl>
          <dt>
            <I18n>Completed in</I18n>:
          </dt>
          <dd>
            {duration} {duration === 1 ? <I18n>day</I18n> : <I18n>days</I18n>}
          </dd>
        </dl>
      </div>
    )
  );
};

const BannerBarRequestShow = compose(
  connect(
    state => ({
      submission: state.submission.data,
      error: state.submission.error,
      kappSlug: state.app.kappSlug,
      navigate,
    }),
    {
      clearSubmission: actions.clearSubmissionRequest,
      fetchSubmission: actions.fetchSubmissionRequest,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchSubmission(this.props.submissionId);
    },
    componentWillUnmount() {
      this.props.clearSubmission();
    },
  }),
)(
  ({ submission, error, kappSlug }) =>
    !error &&
    submission && (
      <div className="banner-bar--request">
        <span>
          <div>
            <h1>
              <I18n>{submission.values['Originating Category']}</I18n>
            </h1>
            <p>
              <I18n context={`kapps.${kappSlug}.forms.${submission.form.slug}`}>
                {submission.form.name}
              </I18n>
            </p>
          </div>
          <div className="btn-group-col">
            <CloneButtonContainer submission={submission} />
            <CancelButtonContainer
              submission={submission}
              disabled={getStatus(submission) !== 'Pending Approval'}
            />
          </div>
        </span>
        <div className="data-list-row">
          <StatusItem submission={submission} />
          <DisplayDateItem submission={submission} />
          <ServiceOwnerItem submission={submission} />
          <CompletedInItem submission={submission} />
        </div>
      </div>
    ),
);

const BannerBarForm = compose(
  connect(
    (state, props) => {
      const subCategory = state.servicesApp.categoryGetter(
        props.subCategorySlug,
      );
      const form =
        subCategory &&
        subCategory.forms.find(form => form.slug === props.formSlug);
      return {
        submission: state.submission.data,
        kappSlug: state.app.kappSlug,
        form: form ? form : state.utility.form,
        formSlug: props.formSlug,
        authenticated: state.app.authenticated,
        subCategory,
      };
    },
    {
      clearSubmission: actions.clearSubmissionRequest,
      fetchSubmission: actions.fetchSubmissionRequest,
    },
  ),
  lifecycle({
    componentDidMount() {
      // If match /requests/:type/request/:subid we get the submission.
      // The submission should have a value of the forms category.
      if (this.props.submissionId) {
        this.props.fetchSubmission(this.props.submissionId);
      }
    },
    componentWillUnmount() {
      this.props.clearSubmission();
    },
  }),
)(({ kappSlug, form, formSlug, submission, subCategory, navigate }) => (
  <div className="banner-bar--request">
    <span>
      <div>
        {subCategory || submission ? (
          <h1>
            {submission && submission.values['Originating Form Name'] ? (
              <I18n>
                Approval - {submission.values['Originating Form Name']}
              </I18n>
            ) : (
              <I18n>
                {subCategory
                  ? subCategory.name
                  : submission.values['Originating Category']}
              </I18n>
            )}
          </h1>
        ) : (
          <h1>
            <I18n>Form Display</I18n>
          </h1>
        )}
        {(formSlug || (form && form.type !== 'Approval')) && (
          <p>
            <I18n
              context={`kapps.${kappSlug}.forms.${
                formSlug ? formSlug : form.slug
              }`}
            >
              {form ? form.name : ''}
            </I18n>
          </p>
        )}
      </div>
      {form &&
        form.type !== 'Approval' &&
        submission && (
          <div className="btn-group-col">
            <CancelButtonContainer
              submission={submission}
              navigate={navigate}
            />
          </div>
        )}
    </span>
  </div>
));
