import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import { StateListWrapper } from '@kineticdata/bundle-common';
import { PaginationButtons } from '../shared/PaginationButtons';
import { PageTitle } from '../shared/PageTitle';

/**
 * Logic for Search Result card.
 *  1. Has "5s" (FCPS terminology) direct towards service description page.
 *     a. The form has category and subcategory slug. (normal use)
 *     b. External Link forms may have the "5s".
 *  2. Has redirect url open page in new tab.
 *  3. Does not have a redirect url or "5s" (means its a Fix my) go directly
 *     to form.
 */
export const getSearchResultTitle = (form, appLocation, redirectURL) => {
  if (form.hasFives) {
    if (form.categorySlug && form.subCategorySlug) {
      return (
        <Link
          to={
            `${appLocation}/categories/${form.categorySlug}/` +
            `${form.subCategorySlug}/${form.slug}/description`
          }
        >
          <h4>{`${form.subCategoryName}: ${form.name}`}</h4>
        </Link>
      );
    } else {
      return (
        <Link to={`${appLocation}/${form.slug}/description`}>
          <h4>{`${form.name}`}</h4>
        </Link>
      );
    }
  } else if (redirectURL) {
    return (
      <a href={redirectURL} target="_blank" rel="noopener noreferrer">
        <h4>{`${form.name}`}</h4>
      </a>
    );
  } else {
    return (
      <Link to={`${appLocation}/forms/${form.slug}`}>
        <h4>{`${form.name}`}</h4>
      </Link>
    );
  }
};

export const CatalogSearchResults = ({
  query,
  error,
  forms,
  paging,
  hasNextPage,
  hasPreviousPage,
  pageIndexStart,
  pageIndexEnd,
  loadPreviousHandler,
  loadNextHandler,
  categoryHelper,
  appLocation,
}) => (
  <div>
    <PageTitle parts={[query, 'Search']} />
    <div className="page__search-results">
      <div className="page--panel page--panel--column">
        <div
          className="search-results"
          aria-label={`Search results for ${query}`}
        >
          <StateListWrapper
            data={forms}
            error={error}
            loadingTitle="Searching"
            emptyTitle="No results found"
            emptyMessage="Make sure words are spelled correctly, use less specific or different keywords"
          >
            {data => (
              <Fragment>
                <div>
                  <ul className="search-results__wrapper">
                    {data
                      // FCPS has the form listed under each of it's categories.
                      // To accomplish this a reduce "expands" the number of forms.
                      .reduce((acc, form) => {
                        if (form.categories.length > 0) {
                          form.categories.forEach(category => {
                            const categoryGetter = categoryHelper.getCategory(
                              category,
                            );
                            let localForm = {
                              ...form,
                              subCategorySlug: category,
                              subCategoryName: categoryGetter.name,
                            };

                            if (
                              categoryHelper.getCategory(category).hasParent()
                            ) {
                              localForm[
                                'categorySlug'
                              ] = categoryGetter.getParent().slug;
                            }

                            acc.push(localForm);
                          });
                        } else {
                          acc.push(form);
                        }
                        return acc;
                      }, [])
                      .map((form, idx) => {
                        let { redirectURL } = form;

                        // redirect url is missing https or http append https.
                        if (
                          redirectURL &&
                          !(
                            redirectURL.toLowerCase().startsWith('https://') ||
                            redirectURL.toLowerCase().startsWith('http://')
                          )
                        ) {
                          redirectURL = `https://${redirectURL}`;
                        }

                        return (
                          <li
                            className="search-results__entry"
                            key={form.slug + idx}
                          >
                            {getSearchResultTitle(
                              form,
                              appLocation,
                              redirectURL,
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: form.description,
                              }}
                            />
                          </li>
                        );
                      })}
                    <li>
                      <PaginationButtons
                        {...{
                          loading: paging,
                          hasNextPage,
                          hasPreviousPage,
                          startIndex: pageIndexStart,
                          endIndex: pageIndexEnd,
                          getPreviousPage: loadPreviousHandler,
                          getNextPage: loadNextHandler,
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
          </StateListWrapper>
        </div>
      </div>
    </div>
  </div>
);
