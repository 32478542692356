import React, { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { connect } from '../../redux/store';
import { actions } from '../../redux/modules/activityList';
import { actions as utilityActions } from '../../redux/modules/utility';
import { ErrorMessage, LoadingMessage } from '@kineticdata/bundle-common';
import { PageTitle } from '../shared/PageTitle';
import { I18n } from '@kineticdata/react';
import { WorkLogs, AddWorkLog } from './WorkLogs';
import moment from 'moment';

export const RemedyRequestComponent = ({
  workOrder,
  error,
  kappSlug,
  loading,
  workLogs,
  type,
}) =>
  !loading && (
    <div className="page--panel">
      <PageTitle parts={[workOrder && `#${workOrder.Id}`, 'Requests']} />
      <div className="page-panel__body">
        <div className="page--panel--three-quarters">
          {error && (
            <ErrorMessage
              title="Failed to load work order"
              message={error.message}
            />
          )}
          {!error && !workOrder && <LoadingMessage />}
          {!error &&
            workOrder && (
              <Fragment>
                <h2>
                  <I18n>{workOrder.Summary}</I18n>
                </h2>
                <div className="submission-title">
                  <div className="notes request-container">
                    <div className="h4">Request ID</div>
                    <p>{workOrder.Id}</p>
                    <div className="h4">
                      <I18n>Notes</I18n>
                    </div>
                    <div className="request-notes-overflow">
                      {workOrder.Notes.split('\n').map((item, i) => (
                        <p key={i}>{item}</p>
                      ))}
                    </div>
                    {workOrder['Created At'] &&
                    workOrder['Created At'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Submit Date</I18n>
                        </div>
                        <p>{moment(workOrder['Created At']).format('LLL')}</p>
                      </Fragment>
                    ) : null}
                    {workOrder['Requested By'] &&
                    workOrder['Requested By'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Requested By</I18n>
                        </div>
                        <p>{workOrder['Requested By']}</p>
                      </Fragment>
                    ) : null}
                    {workOrder['Equipment Location'] &&
                    workOrder['Equipment Location'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Equipment Location</I18n>
                        </div>
                        <p>{workOrder['Equipment Location']}</p>
                      </Fragment>
                    ) : null}
                    {workOrder['Assignee'] &&
                    workOrder['Assignee'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Assignee</I18n>
                        </div>
                        <p>{workOrder['Assignee']}</p>
                      </Fragment>
                    ) : null}
                    {workOrder['Change Coordinator'] &&
                    workOrder['Change Coordinator'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Change Coordinator</I18n>
                        </div>
                        <p>{workOrder['Change Coordinator']}</p>
                      </Fragment>
                    ) : null}
                    {workOrder['Resolution'] &&
                    workOrder['Resolution'].trim().length > 0 ? (
                      <Fragment>
                        <div className="h4">
                          <I18n>Resolution</I18n>
                        </div>
                        <p>{workOrder['Resolution']}</p>
                      </Fragment>
                    ) : null}
                  </div>
                </div>
                <div className="itsm">
                  <div className="h4">
                    Work Log Entries{' '}
                    <span className="work-log-click-to-expand-text">
                      (click to expand)
                    </span>
                  </div>
                  {!error && !workLogs && <LoadingMessage />}
                  {!error &&
                    workLogs && (
                      <Fragment>
                        <div className="work-log-container">
                          <WorkLogs workLogs={workLogs} />
                        </div>
                        <AddWorkLog
                          kappSlug={kappSlug}
                          values={{ Id: workOrder.Id, Type: type }}
                          formSlug="add-work-log"
                        />
                      </Fragment>
                    )}
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </div>
  );

export const mapStateToProps = (state, props) => ({
  workOrder: state.activityList.data,
  error: state.activityList.error,
  loading: state.activityList.loading,
  listType: props.type,
  mode: props.mode,
  kappSlug: state.app.kappSlug,
  appLocation: state.app.location,
  workLogs: state.activityList.workLogs,
  formName: state.utility.formName,
});

export const mapDispatchToProps = {
  clearActivity: actions.clearActivity,
  fetchITSMRecord: actions.fetchITSMRecord,
  fetchITSMWorkLogs: actions.fetchITSMWorkLogs,
  setFormName: utilityActions.setFormName,
  setBreadcrumb: utilityActions.setBreadcrumb,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchITSMRecord({
        type: this.props.type,
        id: this.props.id,
      });
      this.props.fetchITSMWorkLogs({
        type: `${this.props.type}WorkLogs`,
        id: this.props.id,
      });
    },
    componentWillUpdate(nextProps) {
      if (nextProps.workOrder != null) {
        nextProps.setFormName(nextProps.workOrder['Form Name']);
      }
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: nextProps.formName,
        },
      ]);
    },
    componentWillUnmount() {
      //this.props.clearActivity();
      this.props.setBreadcrumb([]);
    },
  }),
);

export const RemedyRequest = enhance(RemedyRequestComponent);
