import { push } from 'connected-react-router';
import { compose, withHandlers, lifecycle } from 'recompose';
import { parse } from 'query-string';
import { Survey } from './Survey';
import { actions } from '../../redux/modules/survey';
import { actions as utilityActions } from '../../redux/modules/utility';
import { connect } from '../../redux/store';
import { addToast } from '@kineticdata/bundle-common';

const valuesFromQueryParams = queryParams => {
  const params = parse(queryParams);
  return Object.entries(params).reduce((values, [key, value]) => {
    if (key.startsWith('values[')) {
      const vk = key.match(/values\[(.*?)\]/)[1];
      return { ...values, [vk]: value };
    }
    return values;
  }, {});
};

export const handleCompleted = props => response => {
  // Check if either currentPage is null (pre form consolidation) or
  // displayedPage.type is not 'confirmation' (post form-consolidation)
  // to determine that there is no confirmation page and we should redirect.
  if (
    !response.submission.currentPage ||
    (response.submission.displayedPage &&
      response.submission.displayedPage.type !== 'confirmation')
  ) {
    props.push(
      `/kapps/${props.kappSlug}/surveys/${props.submission.form.slug}/${
        response.submission.id
      }/confirmation`,
    );
  }

  addToast('Thank you for your submission');
};

export const handleCreated = props => response => {
  // Redirect to route with submission id if submission is not submitted or
  // there is a confirmation page to render, defined as currentPage is set and
  // displayedPage is undefined (pre form consolidation) or displayedPage.type
  // is 'confirmation' (post form-consolidation).
  if (
    response.submission.coreState !== 'Submitted' ||
    (response.submission.currentPage &&
      (!response.submission.displayedPage ||
        response.submission.displayedPage.type === 'confirmation'))
  ) {
    props.push(`${props.location.pathname}/${response.submission.id}`);
  }
};

export const handleLoaded = props => form => {
  props.setForm({
    slug: form.slug(),
    name: form.name(),
    description: form.description(),
    type: form.type(),
  });
};

export const handleDelete = props => () => {
  const deleteCallback = () => {
    props.push(props.appLocation);
  };
  props.deleteSubmission({ id: props.submissionId, callback: deleteCallback });
};

export const mapStateToProps = (state, { formSlug }) => {
  return {
    survey: state.survey.survey,
    loading: state.survey.loading,
    values: valuesFromQueryParams(state.router.location.search),
    kappSlug: state.app.kappSlug,
    appLocation: state.app.location,
    submission: state.submission.data,
    formSlug,
  };
};

export const mapDispatchToProps = {
  push,
  fetchSurvey: actions.fetchSurvey,
  setBreadcrumb: utilityActions.setBreadcrumb,
  setForm: utilityActions.setForm,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({ handleCompleted, handleCreated, handleLoaded, handleDelete }),
  lifecycle({
    componentDidMount() {
      this.props.fetchSurvey(this.props.formSlug);
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'Give Ticket Feedback',
          path: `/surveys`,
        },
      ]);
    },
    componentDidUpdate(prevProps) {
      const { survey } = this.props;

      if (survey !== prevProps.survey) {
        this.props.setBreadcrumb([
          {
            displayText: 'Home',
            path: '',
          },
          {
            displayText: 'Give Ticket Feedback',
            path: `/surveys`,
          },
          {
            displayText: `${survey.name}`,
          },
        ]);
      }
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
);

export const SurveyContainer = enhance(Survey);
