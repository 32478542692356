import React from 'react';
import { connect } from '../../redux/store';
import { compose, withHandlers, withState } from 'recompose';
import { Link } from 'react-router-dom';
import { Avatar } from '@kineticdata/bundle-common';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { bundle } from '@kineticdata/react';
import { I18n } from '@kineticdata/react';

const ProfileDropdownComponent = ({
  profile,
  isOpen,
  toggle,
  children,
  appLocation,
}) => (
  <Dropdown isOpen={isOpen} toggle={toggle}>
    <DropdownToggle
      nav
      role="button"
      className="profile-dropdown"
      style={{ padding: '0 0.75rem' }}
    >
      <Avatar size={36} user={profile} previewable={false} />
    </DropdownToggle>
    <DropdownMenu right className="profile-menu">
      <div className="profile-links">
        <Link
          to={`${appLocation}/profile`}
          className="dropdown-item"
          onClick={toggle}
          role="menuitem"
        >
          <I18n>Profile</I18n>
        </Link>
        <div className="dropdown-divider" role="none" />
        <a
          onClick={() => localStorage.removeItem('token')}
          href={`${bundle.spaceLocation()}/app/logout`}
          className="dropdown-item"
          role="menuitem"
        >
          <I18n>Logout</I18n>
        </a>
      </div>
      <div className="dropdown-divider" />
      {profile.spaceAdmin && children}
    </DropdownMenu>
  </Dropdown>
);

const mapStateToProps = state => ({
  profile: state.app.profile,
  appLocation: state.app.location,
});

export const ProfileDropdown = compose(
  connect(mapStateToProps),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    toggle: props => () => props.setIsOpen(open => !open),
  }),
)(ProfileDropdownComponent);
