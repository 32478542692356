import { reducer as app } from './modules/app';
import servicesAppReducer from './modules/servicesApp';
import searchReducer from './modules/search';
import submissionsReducer from './modules/submissions';
import submissionReducer from './modules/submission';
import settingsFormsReducer from './modules/settingsForms';
import settingsCategoriesReducer from './modules/settingsCategories';
import quickLinksReducer from './modules/quickLinks';
import utilityReducer from './modules/utility';
import activityListReducer from './modules/activityList';
import alertsReducer from './modules/alerts';
import formReducer from './modules/form';
import surveyReducer from './modules/survey';

export default {
  app,
  search: searchReducer,
  servicesApp: servicesAppReducer,
  submissions: submissionsReducer,
  submission: submissionReducer,
  settingsForms: settingsFormsReducer,
  settingsCategories: settingsCategoriesReducer,
  quickLinks: quickLinksReducer,
  utility: utilityReducer,
  activityList: activityListReducer,
  alerts: alertsReducer,
  form: formReducer,
  survey: surveyReducer,
};
