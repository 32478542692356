import { compose, withProps } from 'recompose';
import { connect } from '../../redux/store';
import {
  PageTitle as CommonPageTitle,
  selectCurrentKapp,
} from '@kineticdata/bundle-common';

export const mapStateToProps = state => ({
  space: state.app.space || 'Home',
  kapp: selectCurrentKapp(state),
});

export const PageTitle = compose(
  connect(mapStateToProps),
  withProps(({ parts = [], ...props }) => {
    return {
      pageTitleParts: parts.concat([
        [props.kapp && props.kapp.name, props.settings && 'Settings']
          .filter(Boolean)
          .join(' '),
        props.space && props.space.name,
      ]),
    };
  }),
)(CommonPageTitle);
