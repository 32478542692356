import React from 'react';
import { Navbar, Nav } from 'reactstrap';
import { connect } from '../../redux/store';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { Link } from 'react-router-dom';
import { selectVisibleKapps } from '@kineticdata/bundle-common';
import { ProfileDropdown } from './ProfileDropdown';
import { I18n } from '@kineticdata/react';
import { actions as utilityActions } from '../../redux/modules/utility';
import { CatalogSearchContainer } from '../shared/CatalogSearchContainer';
import logo from '../../assets/images/FCPS-LOGO-SMALL.svg';
import bell from '../../assets/images/notifications-24px.svg';

export const HeaderComponent = ({
  kappDropdownToggle,
  displaySearch,
  breadcrumb,
  appLocation,
  alertDismissed,
  alerts,
  setAlertModalOpen,
  setSidebarDisplay,
  layoutSize,
}) => (
  <header>
    <Navbar color="faded" light>
      <Nav className="nav-header">
        <li className="nav-header__left">
          <ul>
            <li>
              {/* <Link to={appLocation}> */}
              <div className="logo-main">
                <img src={logo} alt="fcps logo" width="120px" />
              </div>
              {/* </Link> */}
            </li>
            {layoutSize === 'xlarge' && (
              <li>
                <nav aria-label="breadcrumb">
                  <ol className="navigation--breadcrumb">
                    {/* {breadcrumb.length > 0 &&
                      breadcrumb.map(
                        (segment, idx) =>
                          segment.path !== undefined ? (
                            <li
                              key={`segment-${idx}`}
                              className="navigation--breadcrumb__item"
                            >
                              <Link to={`${appLocation}${segment.path}`}>
                                {segment.displayText}
                              </Link>
                              <span>/</span>
                            </li>
                          ) : (
                            <li
                              key={`segment-${idx}`}
                              aria-current="page"
                              className="navigation--breadcrumb__item"
                            >
                              <p>{segment.displayText}</p>
                            </li>
                          ),
                      )} */}
                  </ol>
                </nav>
              </li>
            )}
          </ul>
        </li>
        <li className="nav-header__right">
          <ul>
            {/* {displaySearch && layoutSize !== 'xlarge' ? (
              <Link
                to={'/'}
                className="search--mobile"
                type="submit"
                aria-label="Submit Search"
              >
                <span className="fa fa-fw fa-search" />
              </Link>
            ) : (
              displaySearch && (
                <li className="search--header">
                  <div className="search-box">
                    <CatalogSearchContainer />
                  </div>
                </li>
              )
            )} */}
            <li className="alert--notification">
              <div
                className={
                  alerts && !alertDismissed && alerts.size > 0
                    ? 'alert--notification__icon'
                    : undefined
                }
              />
              <button
                disabled={alerts && alerts.size <= 0}
                type="button"
                aria-label="Open Alerts Modal"
                onClick={setAlertModalOpen}
              >
                <img src={bell} alt="Alarm icon" />
              </button>
            </li>
            {layoutSize !== 'small' && (
              <li className="navigation--profile">
                <ProfileDropdown>
                  <Link
                    className="dropdown-item"
                    to="/discussions"
                    onClick={kappDropdownToggle}
                    role="menuitem"
                  >
                    <I18n>Discussions</I18n>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/teams"
                    onClick={kappDropdownToggle}
                    role="menuitem"
                  >
                    <I18n>Teams</I18n>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/settings"
                    onClick={kappDropdownToggle}
                    role="menuitem"
                  >
                    <I18n>Settings</I18n>
                  </Link>
                </ProfileDropdown>
              </li>
            )}
            {layoutSize !== 'xlarge' && (
              <li>
                <span className="navigation--menu">
                  <button
                    type="button"
                    aria-label="Open Sidebar Menu"
                    onClick={setSidebarDisplay}
                  >
                    <i className="fa fa-bars" area-hidden="true" />
                    <p>Menu</p>
                  </button>
                </span>
              </li>
            )}
          </ul>
        </li>
      </Nav>
    </Navbar>
  </header>
);

export const mapStateToProps = state => ({
  loading: state.app.loading,
  kapp: state.app.kapp,
  pathname: state.router.location.pathname,
  appLocation: state.app.location,
  // Selectors
  visibleKapps: selectVisibleKapps(state),
  breadcrumb: state.utility.breadcrumb,
  alertDismissed: state.utility.dismissed,
  modalOpen: state.utility.alertModalOpen,
  alerts: state.alerts.get('data'),
  layoutSize: state.app.layoutSize,
});

export const mapDispatchToProps = {
  setModalOpen: utilityActions.setAlertModalOpen,
  setSidebarDisplay: utilityActions.setSidebarDisplay,
};

export const Header = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('kappDropdownOpen', 'setKappDropdownOpen', false),
  withProps(({ pathname, appLocation }) => ({
    displaySearch: pathname.replace(appLocation, '').length > 0,
  })),
  withHandlers({
    kappDropdownToggle: props => () => props.setKappDropdownOpen(open => !open),
    setAlertModalOpen: props => () => props.setModalOpen(!props.modalOpen),
    setSidebarDisplay: props => () => props.setSidebarDisplay('open'),
  }),
)(HeaderComponent);
