import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { fetchForms } from '@kineticdata/react';
import { actions, types } from '../modules/search';
import { addToastAlert } from '@kineticdata/bundle-common';
import { SEARCH_LIMIT } from '../../constants';

export function* fetchSearchRecordsSaga({ payload }) {
  const kappSlug = yield select(state => state.app.kappSlug);
  const pageToken = yield select(state => state.search.pageToken);

  let query = '';
  if (payload) {
    query =
      'type = "Service"' +
      'AND status IN ("Active", "New")' +
      `AND (name *=* "${payload.query}" OR category *=* "${
        payload.query
      }" OR ` +
      `attributes[Keyword] *=* "${payload.query}" OR description *=* "${
        payload.query
      }")`;
  }

  const [{ forms, error, nextPageToken }] = yield all([
    call(fetchForms, {
      kappSlug,
      include:
        'attributes[Keyword],' +
        'attributes[Redirect URL],' +
        'attributes[Who Can Request This Service],' +
        'categorizations',
      q: query,
      orderBy: 'slug',
      direction: 'ASC',
      limit: SEARCH_LIMIT,
      pageToken,
    }),
  ]);

  if (error) {
    addToastAlert('Unable to fetch search results');
    console.error(`There was an error when fetching search results: ${error}`);
  } else {
    yield put(actions.fetchSearchRecordsSuccess({ forms, nextPageToken }));
  }
}

export function* watchSearch() {
  yield takeEvery(
    [
      types.FETCH_SEARCH_RECORDS,
      types.FETCH_SEARCH_RECORDS_NEXT,
      types.FETCH_SEARCH_RECORDS_PREVIOUS,
    ],
    fetchSearchRecordsSaga,
  );
}
