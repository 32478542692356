import { compose, lifecycle } from 'recompose';
import { CategoryList } from './CategoryList';
import { connect } from '../../redux/store';
import { actions as utilityActions } from '../../redux/modules/utility';

const mapStateToProps = state => {
  const categories =
    state.servicesApp.categories &&
    state.servicesApp.categories.filter(category => category.slug !== 'fix-my');

  return {
    categories,
    appLocation: state.app.location,
  };
};

const mapDispatchToProps = {
  setBreadcrumb: utilityActions.setBreadcrumb,
};

export const CategoryListContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.setBreadcrumb([
        {
          displayText: 'Home',
          path: '',
        },
        {
          displayText: 'IT Service Catalog',
        },
      ]);
    },
    componentWillUnmount() {
      this.props.setBreadcrumb([]);
    },
  }),
)(CategoryList);
