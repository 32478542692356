import { LOCATION_CHANGE } from 'connected-react-router';
import { Form } from '../../models';
import { Utils } from '@kineticdata/bundle-common';
import { Record, List } from 'immutable';
const { withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/search');

export const types = {
  FETCH_SEARCH_RECORDS: ns('FETCH_SEARCH_RECORDS'),
  FETCH_SEARCH_RECORDS_NEXT: ns('FETCH_SEARCH_RECORDS_NEXT'),
  FETCH_SEARCH_RECORDS_PREVIOUS: ns('FETCH_SEARCH_RECORDS_PREVIOUS'),
  FETCH_SEARCH_RECORDS_SUCCESS: ns('FETCH_SEARCH_RECORDS_SUCCESS'),
  SEARCH_INPUT_CHANGE: ns('SEARCH_INPUT_CHANGE'),
  SET_COUNT: ns('SET_COUNT'),
};

export const actions = {
  fetchSearchRecords: withPayload(types.FETCH_SEARCH_RECORDS),
  fetchSearchRecordsSuccess: withPayload(types.FETCH_SEARCH_RECORDS_SUCCESS),
  fetchSearchRecordsNext: withPayload(types.FETCH_SEARCH_RECORDS_NEXT),
  fetchSearchRecordsPrevious: withPayload(types.FETCH_SEARCH_RECORDS_PREVIOUS),
  searchInputChange: value => ({
    type: types.SEARCH_INPUT_CHANGE,
    payload: value,
  }),
  setCount: withPayload(types.SET_COUNT),
};

export const State = Record({
  inputValue: '',
  error: null,
  data: List(),
  paging: false,
  pageToken: null,
  nextPageToken: null,
  previousPageTokens: List(),
  count: null,
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.FETCH_SEARCH_RECORDS:
      return state
        .set('data', null)
        .set('error', null)
        .set('pageToken', null)
        .set('nextPageToken', null)
        .set('previousPageTokens', List());
    case types.FETCH_SEARCH_RECORDS_NEXT:
      return state
        .update('previousPageTokens', t => t.push(state.pageToken))
        .set('pageToken', state.nextPageToken)
        .set('nextPageToken', null)
        .set('paging', true);
    case types.FETCH_SEARCH_RECORDS_PREVIOUS:
      return state
        .set('nextPageToken', null)
        .set('pageToken', state.previousPageTokens.last())
        .update('previousPageTokens', t => t.pop())
        .set('paging', true);
    case types.FETCH_SEARCH_RECORDS_SUCCESS:
      return state
        .set('data', List(payload.forms).map(Form))
        .set('nextPageToken', payload.nextPageToken)
        .set('paging', false);
    case types.SEARCH_INPUT_CHANGE:
      return state.set('inputValue', payload);
    case types.SET_COUNT:
      return state.set('count', payload);
    case LOCATION_CHANGE:
      return state;
    default:
      return state;
  }
};

export default reducer;
