import { Utils } from '@kineticdata/bundle-common';
import { Record } from 'immutable';
const { withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/survey');

export const types = {
  FETCH_SURVEY: ns('FETCH_SURVEY'),
  FETCH_SURVEY_SUCCESS: ns('FETCH_SURVEY_SUCCESS'),
};

export const actions = {
  fetchSurvey: withPayload(types.FETCH_SURVEY),
  fetchSurveySuccess: withPayload(types.FETCH_SURVEY_SUCCESS),
};

export const State = Record({
  survey: null,
  loading: false,
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.FETCH_SURVEY:
      return state.set('loading', true);
    case types.FETCH_SURVEY_SUCCESS:
      return state.set('survey', payload).set('loading', false);
    default:
      return state;
  }
};

export default reducer;
