import { Utils } from '@kineticdata/bundle-common';
import { Record } from 'immutable';
import { ServiceDescription } from '../../models';
const { withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/quickLinks');

export const types = {
  FETCH_SERVICE_DESCRIPTION: ns('FETCH_SERVICE_DESCRIPTION'),
  FETCH_SERVICE_DESCRIPTION_SUCCESS: ns('FETCH_SERVICE_DESCRIPTION_SUCCESS'),
};

export const actions = {
  fetchServiceDescription: withPayload(types.FETCH_SERVICE_DESCRIPTION),
  fetchServiceDescriptionSuccess: withPayload(
    types.FETCH_SERVICE_DESCRIPTION_SUCCESS,
  ),
};

export const State = Record({
  serviceDescription: null,
  slug: null,
  name: null,
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.FETCH_SERVICE_DESCRIPTION_SUCCESS:
      return state
        .set('serviceDescription', ServiceDescription(payload))
        .set('slug', payload.slug)
        .set('name', payload.name);
    default:
      return state;
  }
};

export default reducer;
