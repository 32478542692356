import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchForm } from '@kineticdata/react';
import { addToastAlert } from '@kineticdata/bundle-common';
import { actions, types } from '../modules/survey';

export function* fetchSurveySaga({ payload }) {
  const { form, error } = yield call(fetchForm, {
    kappSlug: 'survey',
    formSlug: payload,
    include: 'details',
  });

  if (error) {
    addToastAlert('Unable to fetch survey');
  } else {
    yield put(actions.fetchSurveySuccess(form));
  }
}

export function* watchSurvey() {
  yield takeEvery(types.FETCH_SURVEY, fetchSurveySaga);
}
