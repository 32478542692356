import React from 'react';
import { Link } from '@reach/router';
import { I18n } from '@kineticdata/react';
import { getImage } from '../../assets/js/imagesMap';

export const CategoryCard = ({ path, category }) => (
  <Link
    className="card card--category"
    to={path}
    style={{ backgroundImage: `url(${getImage(category.icon.split('.')[0])})` }}
  >
    <div className="card--category__text-wrapper">
      <p>
        <I18n>{category.name}</I18n>
      </p>
    </div>
  </Link>
);

export const CustomerFeedbackCard = ({ href }) => (
  <a
    className="card card--category card--category--feedback"
    href={href}
    target="_blank"
    title="Submit Feedback"
    rel="noopener noreferrer"
  >
    <div className="card--category__text-wrapper">
      <h2>
        <I18n>How are we doing?</I18n>
        <div className="card-subtext">
          <I18n>Submit IT Customer Feedback</I18n>
        </div>
      </h2>
    </div>
  </a>
);

export const ProblemCategoryCard = ({ path, category }) => (
  <Link to={path} className="card card--category card--category--report_issue">
    <div className="card--category__text-wrapper">
      <h2>
        <I18n>{category.name}</I18n>
      </h2>
    </div>
  </Link>
);
