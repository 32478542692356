import { Record } from 'immutable';
import { CategoryHelper } from '../../models';
import { Utils } from '@kineticdata/bundle-common';
const { noPayload, withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/servicesApp');

export const types = {
  FETCH_APP_DATA_REQUEST: ns('FETCH_APP_DATA_REQUEST'),
  FETCH_APP_DATA_SUCCESS: ns('FETCH_APP_DATA_SUCCESS'),
  FETCH_APP_DATA_FAILURE: ns('FETCH_APP_DATA_FAILURE'),
};

export const actions = {
  fetchAppDataRequest: noPayload(types.FETCH_APP_DATA_REQUEST),
  fetchAppDataSuccess: withPayload(types.FETCH_APP_DATA_SUCCESS),
  fetchAppDataFailure: withPayload(types.FETCH_APP_DATA_FAILURE),
};

export const State = Record({
  loading: true,
  error: null,
  categoryGetter: () => null,
  categories: null,
  subCategories: null,
  categoryHelper: null,
});

const reducer = (state = State(), { type, payload }) => {
  switch (type) {
    case types.FETCH_APP_DATA_REQUEST:
      return state.set('error', null);
    case types.FETCH_APP_DATA_SUCCESS:
      const categoryHelper = CategoryHelper(payload.categories);

      return state
        .set('loading', false)
        .set('categoryHelper', categoryHelper)
        .set('categoryGetter', slug => categoryHelper.getCategory(slug))
        .set(
          'subCategories',
          categoryHelper
            .getRootCategories()
            .flatMap(c => c.getChildren())
            .sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              } else {
                return 0;
              }
            })
            .groupBy(category => category.name[0].toLowerCase()),
        )
        .set(
          'categories',
          categoryHelper
            .getRootCategories()
            .filterNot(category => category.isEmpty()),
        );
    case types.FETCH_APP_DATA_FAILURE:
      return state.set('loading', false).set('error', payload);
    default:
      return state;
  }
};

export default reducer;
