import { Utils } from '@kineticdata/bundle-common';
import { Record } from 'immutable';
const { withPayload, noPayload } = Utils;
const ns = Utils.namespaceBuilder('services/utility');

export const types = {
  SET_BREADCRUMB: ns('SET_BREADCRUMB'),
  DISMISS_ALERT: ns('DISMISS_ALERT'),
  OPEN_ALERT_MODAL: ns('OPEN_ALERT_MODAL'),
  SET_FORM: ns('SET_FORM'),
  SET_SIDEBAR_DISPLAY: ns('SET_SIDEBAR_DISPLAY'),
  SET_FORM_NAME: ns('SET_FORM_NAME'),
};

export const actions = {
  setBreadcrumb: withPayload(types.SET_BREADCRUMB),
  setDismissAlert: noPayload(types.DISMISS_ALERT),
  setAlertModalOpen: withPayload(types.OPEN_ALERT_MODAL),
  setForm: withPayload(types.SET_FORM),
  setSidebarDisplay: withPayload(types.SET_SIDEBAR_DISPLAY),
  setFormName: withPayload(types.SET_FORM_NAME),
};

export const State = Record({
  breadcrumb: [],
  dismissed: false,
  modalOpen: false,
  form: null,
  sidebarDisplay: 'close',
  formName: null,
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.SET_BREADCRUMB:
      return state.set('breadcrumb', payload);
    case types.DISMISS_ALERT:
      return state.set('dismissed', true);
    case types.OPEN_ALERT_MODAL:
      return state.set('modalOpen', payload);
    case types.SET_FORM:
      return state.set('form', payload);
    case types.SET_SIDEBAR_DISPLAY:
      return state.set('sidebarDisplay', payload);
    // This form name is for Remedy Inc/Crq banner display
    case types.SET_FORM_NAME:
      return state.set('formName', payload);
    default:
      return state;
  }
};

export default reducer;
