import React from 'react';
import { Redirect, Router } from '@reach/router';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { ErrorUnexpected, Loading } from '@kineticdata/bundle-common';
import { connect } from './redux/store';

import { actions as categoriesActions } from './redux/modules/servicesApp';
import { actions as searchActions } from './redux/modules/search';
import { actions as alertsActions } from './redux/modules/alerts';
import { Header } from './components/header/Header';
import { Footer } from './components/Footer';
import { Sidebar } from './components/sidebar/Sidebar';
import { CatalogContainer } from './components/home/CatalogContainer';
import { CategoryListContainer } from './components/category_list/CategoryListContainer';
import { CategoryContainer } from './components/category/CategoryContainer';
import { ServiceDescription } from './components/category/ServiceDescription';
import { CatalogSearchResultsContainer } from './components/search_results/CatalogSearchResultsContainer';
import { FormContainer } from './components/form/FormContainer';
import { RequestShowContainer } from './components/request/RequestShowContainer';
import { RemedyRequest } from './components/request/RemedyRequest';
import { Settings } from './components/settings/Settings';
import { MyApprovals } from './components/activity_list/MyApprovals';
import { PendingApprovals } from './components/activity_list/PendingApprovals';
import { Requests } from './components/activity_list/Requests';
import { Surveys } from './components/activity_list/Surveys';
import { SurveyContainer } from './components/survey/SurveyContainer';
import { BannerBar } from './components/header/BannerBar';
import { AlertModal } from './components/alerts/Alert';
import { Profile } from './components/Profile';
import { I18n } from '@kineticdata/react';

const SubmissionRedirect = props => (
  <Redirect
    to={`${props.appLocation}/requests/request/${props.id}/${
      props.location.search.includes('review') ? 'review' : 'activity'
    }`}
    noThrow
  />
);

/*****************************************************************************
 *** PRIVATE APP
 *****************************************************************************/

export const AppComponent = props => {
  if (props.error) {
    return <ErrorUnexpected />;
  } else if (props.loading) {
    return <Loading text="App is loading ..." />;
  } else {
    return props.render({
      main: (
        <>
          <I18n>
            <main
              className="package-layout package-layout--services"
              style={{ flexDirection: 'row' }}
            >
              <div className="layout__wrapper">
                <Header />
                {!props.frameless && (
                  <BannerBar appLocation={props.appLocation} />
                )}
                <div className="layout__page__body">
                  <Router>
                    <Settings path="settings/*" />
                    <SubmissionRedirect
                      path="submissions/:id"
                      appLocation={props.appLocation}
                    />
                    <SubmissionRedirect
                      path="forms/:formSlug/submissions/:id"
                      appLocation={props.appLocation}
                    />

                    <CatalogContainer
                      path="/"
                      homePageMode={props.homePageMode}
                      homePageItems={props.homePageItems}
                    />
                    <MyApprovals path="/my-approvals" />
                    <PendingApprovals path="/pending-approvals" />
                    <Requests path="/requests" />
                    <Surveys path="/surveys" />
                    <CategoryListContainer path="categories" />
                    <CategoryContainer path="categories/:categorySlug" />
                    <ServiceDescription path="categories/:categorySlug/:subcategorySlug/:formSlug/description" />
                    <ServiceDescription path=":formSlug/description" />
                    <FormContainer path="categories/:categorySlug/:subCategorySlug/:formSlug" />
                    <FormContainer path="categories/:categorySlug/:subCategorySlug/:formSlug/:submissionId" />
                    <FormContainer path="forms/:formSlug" />
                    <FormContainer path="forms/:formSlug/:submissionId" />
                    {/* <CatalogSearchResultsContainer path="search" />
                    <CatalogSearchResultsContainer path="search/:query" /> */}
                    <FormContainer path="requests/request/:submissionId" />
                    <FormContainer path="requests/:type/request/:submissionId" />
                    <RemedyRequest path="/request/:type/:id" />
                    <RequestShowContainer path="/requests/request/:submissionId/:mode" />
                    <RequestShowContainer path="/requests/:type/request/:submissionId/:mode" />
                    <Profile path="profile" />
                    <SurveyContainer path="surveys/:formSlug" />
                    <SurveyContainer path="surveys/:formSlug/:submissionId" />
                  </Router>
                </div>
                <Footer />
                <Sidebar />
                <AlertModal />
              </div>
            </main>
          </I18n>
        </>
      ),
    });
  }
};

const mapStateToProps = state => {
  return {
    loading: state.servicesApp.loading,
    error: state.servicesApp.error,
    categories: state.servicesApp.categories,
    pathname: state.router.location.pathname,
    kappSlug: state.app.kappSlug,
    appLocation: state.app.location,
    homePageItems: state.servicesApp.categories,
  };
};

const mapDispatchToProps = {
  fetchAppDataRequest: categoriesActions.fetchAppDataRequest,
  fetchAlertsRequest: alertsActions.fetchAlertsRequest,
  fetchSearchRecords: searchActions.fetchSearchRecords,
};

export const App = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState(
    'settingsBackPath',
    'setSettingsBackPath',
    props => `/kapps/${props.kappSlug}`,
  ),
  withHandlers({
    openSettings: props => () => props.setSettingsBackPath(props.pathname),
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchAppDataRequest();
      this.props.fetchAlertsRequest();
    },
  }),
)(AppComponent);

/*****************************************************************************
 *** PUBLIC APP
 *****************************************************************************/

export const PublicAppComponent = props => {
  return props.render({
    main: (
      <I18n>
        <main
          className="package-layout package-layout--services"
          style={{ flexDirection: 'row' }}
        >
          <div className="layout__wrapper">
            <div className="layout__page__body">
              <Router>
                <FormContainer path="forms/:formSlug" />
                <FormContainer path="forms/:formSlug/:submissionId" />
                <Redirect from="*" to={props.authRoute} noThrow />
              </Router>
            </div>
          </div>
        </main>
      </I18n>
    ),
  });
};

const mapStateToPropsPublic = state => ({
  authRoute: state.app.authRoute,
});

export const PublicApp = compose(connect(mapStateToPropsPublic))(
  PublicAppComponent,
);
