import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import {
  Utils as ErrorMessage,
  LoadingMessage,
} from '@kineticdata/bundle-common';
import { RequestActivityList } from './RequestActivityList';
import { SendMessageModal } from './SendMessageModal';
import { getSubmissionPath } from '../../utils';
import { ReviewRequest } from './ReviewRequest';
import { PageTitle } from '../shared/PageTitle';

import { I18n } from '@kineticdata/react';
import { isActiveClass } from '../../utils';

export const RequestShow = ({
  submission,
  error,
  listType,
  mode,
  sendMessageModalOpen,
  kappSlug,
  appLocation,
  navigate,
}) => (
  <div className="page__category page__category--panels">
    <div className="page--panel  page--panel--column page--panel--three-fifths">
      <PageTitle parts={[submission && `#${submission.handle}`, 'Requests']} />
      {sendMessageModalOpen && (
        <SendMessageModal navigate={navigate} submission={submission} />
      )}
      <div className="page--panel__body card--request-show">
        {error && (
          <ErrorMessage
            title="Failed to load submission"
            message={error.message}
          />
        )}
        {!error && !submission && <LoadingMessage />}
        {!error &&
          submission && (
            <Fragment>
              <div className="submission-tabs">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      to={getSubmissionPath(
                        appLocation,
                        submission,
                        null,
                        listType,
                      )}
                      getProps={isActiveClass('nav-link')}
                    >
                      <I18n>Timeline</I18n>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={`${getSubmissionPath(
                        appLocation,
                        submission,
                        'review',
                        listType,
                      )}`}
                      getProps={isActiveClass('nav-link')}
                    >
                      <I18n>Review Request</I18n>
                    </Link>
                  </li>
                </ul>
                <div className="submission-tabs__content form-unstyled">
                  {mode === 'review' ? (
                    <ReviewRequest
                      kappSlug={kappSlug}
                      submission={submission}
                    />
                  ) : (
                    <RequestActivityList submission={submission} />
                  )}
                </div>
              </div>
            </Fragment>
          )}
      </div>
    </div>
  </div>
);
