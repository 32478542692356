import React from 'react';

export const PaginationButtons = ({
  getPreviousPage,
  getNextPage,
  hasPreviousPage,
  hasNextPage,
  startIndex,
  endIndex,
  loading,
}) => (
  <div className="pagination-bar" aria-label="Pagination">
    <button
      type="button"
      aria-label="Previous"
      className="btn btn-link icon-wrapper"
      onClick={getPreviousPage}
      disabled={loading || !hasPreviousPage}
    >
      <span className="icon">
        <span className="fa fa-fw fa-caret-left" area-hidden="true" />
      </span>
    </button>
    <small>
      {loading ? (
        <span className="fa fa-spinner fa-spin" />
      ) : (
        <strong>
          <span
            area-current="page"
            className="page-num page-num__current"
          >{`${startIndex}`}</span>-<span className="page-num">{`${endIndex}`}</span>
        </strong>
      )}
    </small>
    <button
      type="button"
      aria-label="Next"
      className="btn btn-link icon-wrapper"
      disabled={loading || !hasNextPage}
      onClick={getNextPage}
    >
      <span className="icon">
        <span className="fa fa-fw fa-caret-right" area-hidden="true" />
      </span>
    </button>
  </div>
);
