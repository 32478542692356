import { Utils } from '@kineticdata/bundle-common';
import { Record, List } from 'immutable';
import { QuickLinks } from '../../models';
const { withPayload } = Utils;
const ns = Utils.namespaceBuilder('services/quickLinks');

export const types = {
  FETCH_QUICK_LINKS: ns('FETCH_QUICK_LINKS'),
  FETCH_QUICK_LINKS_SUCCESS: ns('FETCH_QUICK_LINKS_SUCCESS'),
};

export const actions = {
  fetchQuickLinks: withPayload(types.FETCH_QUICK_LINKS),
  fetchQuickLinksSuccess: withPayload(types.FETCH_QUICK_LINKS_SUCCESS),
};

export const State = Record({
  data: List(),
});

const reducer = (state = State(), { type, payload = {} }) => {
  switch (type) {
    case types.FETCH_QUICK_LINKS_SUCCESS:
      return state.set('data', List(payload).map(QuickLinks));
    default:
      return state;
  }
};

export default reducer;
