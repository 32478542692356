import React, { Fragment } from 'react';
import { compose, withState } from 'recompose';
import { PageTitle } from '../shared/PageTitle';
import { ServiceCard } from '../shared/ServiceCard';

export const SubCategoryCard = compose(withState('open', 'setOpen', false))(
  ({ childCategory, open, setOpen }) => (
    <Fragment>
      <button
        type="button"
        aria-label="Open Subcategory"
        aria-pressed={open}
        onClick={() => setOpen(!open)}
      >
        <div className="card--subcategory__head">
          <div className="card--subcategory__title">
            <p>{childCategory.name}</p>
          </div>
          <i
            className={`fa fa-chevron-down rotate ${open ? 'down' : 'up'}`}
            aria-hidden="true"
          />
        </div>
      </button>
      <div className={`card--subcategory__body expand ${open ? 'down' : 'up'}`}>
        {open &&
          childCategory.forms
            .sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              } else {
                return 0;
              }
            })
            .map(
              form =>
                form.type === 'Service' ? (
                  <Fragment key={form.slug}>
                    <ServiceCard
                      path={`${childCategory.slug}/${form.slug}/description`}
                      form={form}
                    />
                  </Fragment>
                ) : (
                  <Fragment key={form.slug}>
                    <ServiceCard
                      path={`${childCategory.slug}/${
                        form.slug
                      }?values[Originating Category]=${childCategory.name}`}
                      form={form}
                    />
                  </Fragment>
                ),
            )}
      </div>
    </Fragment>
  ),
);

export const Category = ({
  category,
  subcategories,
  subcategoriesLeft,
  subcategoriesRight,
  layoutSize,
}) => (
  <Fragment>
    <PageTitle parts={[category.name, 'Categories']} />
    <div className="page__category">
      <div className="page--panel page--panel--column">
        {subcategories.size > 0 ? (
          <div className="cards__category">
            <div className="cards cards--seconds">
              {layoutSize === 'small' &&
                subcategories &&
                subcategories.map(childCategory => (
                  <div
                    key={childCategory.slug}
                    className="card card--subcategory
                    cards cards--seconds"
                  >
                    <SubCategoryCard childCategory={childCategory} />
                  </div>
                ))}
              {/* Split subcategories into two columns so that when
                * a subcategory card opens it does cause the card next
                * to it to expand
                */}
              {layoutSize !== 'small' && (
                <Fragment>
                  <div className="layout--categories">
                    {subcategoriesLeft &&
                      subcategoriesLeft.map(childCategory => (
                        <div
                          key={childCategory.slug}
                          className="card card--subcategory
                          cards cards--seconds"
                        >
                          <SubCategoryCard childCategory={childCategory} />
                        </div>
                      ))}
                  </div>
                  <div className="layout--categories">
                    {subcategoriesRight &&
                      subcategoriesRight.map(childCategory => (
                        <div
                          key={childCategory.slug}
                          className="card card--subcategory
                          cards cards--seconds"
                        >
                          <SubCategoryCard childCategory={childCategory} />
                        </div>
                      ))}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        ) : (
          <p>This category has all empty subcategories</p>
        )}
      </div>
    </div>
  </Fragment>
);
