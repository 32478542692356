import { Map } from 'immutable';

import accounts from '../../assets/images/accounts.png';
import communications from '../../assets/images/communications.png';
import computer from '../../assets/images/computer.png';
import consult from '../../assets/images/consult.png';
import documents from '../../assets/images/documents.png';
import media from '../../assets/images/media.png';
import software from '../../assets/images/software.png';
import filetexto from '../../assets/images/filetexto.png';

const IMAGES = Map({
  accounts,
  communications,
  computer,
  consult,
  documents,
  media,
  software,
  filetexto,
});

// We need a default image.
export const getImage = imageName => {
  return IMAGES.get(imageName, media);
};

const SERVICE_IMAGES = Map({
  filetexto,
});

export const getServiceImage = imageName => {
  return SERVICE_IMAGES.get(imageName, filetexto);
};
